@import "../../common.scss";
.navbar_wrapper {
  position: fixed;
  width: 100%;
  z-index: 10;
  height: 6.25em;
  text-align: center;
  top: 0;
  left: 0;
  transition: background-color 0.3s;
  .navbar_flex {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .social_and_brochure_wrapper {
    margin-left: 6.25em;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    .brochure_text_wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      padding-left: 3em;
      padding-right: 3em;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        width: 2.5px;
        height: 1.233em;
        background-color: $color-white;
        top: 50%;
        transform: translateY(-50%);
        right: 1.5em;
        transition: all 0.3s;
      }
      &::before {
        content: "";
        position: absolute;
        width: 2.5px;
        height: 1.233em;
        background-color: $color-white;
        top: 50%;
        transform: translateY(-50%);
        left: 1.5em;
        transition: all 0.3s;
      }
    }
    .brochure_text_wrapper.brochure_text_wrapper_black {
      &::after {
        background-color: #000;
      }
      &::before {
        background-color: #000;
      }
    }
    .brochure_text {
      font-size: 1.25em;
      @include poppinsSemiBold;
      color: $color-white;
      text-transform: capitalize;
      padding-left: 0.5em;
      transition: all 0.3s;
    }
    .brochure_text_black.brochure_text {
      color: #000;
    }
    .nav_icons {
      width: 100%;
      max-width: 1.5em;
    }
  }
}
.mcm_logo_wrapper {
  position: fixed;
  z-index: 11;
  top: 1.4em;
  left: 50%;
  transform: translateX(-50%);
  .navbar_mcm_logo {
    width: 100%;
    max-width: 8.813em;
  }
}
.navbar_bg.navbar_wrapper {
  background-color: $color-white;
}

.hamburger_menu {
  position: fixed;
  z-index: 15;
  top: 2em;
  right: 6.25em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  width: 3.5em;
  height: 2.2em;
  cursor: pointer;
  .line {
    height: 3px;
    width: 100%;
    background-color: $color-white;
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }
  .line2 {
    width: 80%;
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }
  .line3 {
    width: 50%;
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }
}
.hamburger_menu.active {
  .line {
    background-color: #fff !important;
  }

  .line1 {
    top: 18px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: translateY(1.2em) rotate(135deg);
  }

  .line2 {
    opacity: 0;
    left: -60px;
  }

  .line3 {
    width: 100%;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: translateY(-0.7em) rotate(-135deg);
  }
}
.hamburger_menu.hamburger_lines_black {
  .line {
    background-color: #000;
  }
}

.project_wrapper {
  // display: none;
  width: 100%;
  max-width: 60%;
  height: 50%;
  position: fixed;
  left: -100%;
  z-index: 13;
  // transition: left 0.3s ease-in-out;
  transition: 0.7s;

  .navimg1 {
    width: 100%;
    height: 100%;
  }
  .project_span {
    font-size: 2.5em;
    @include poppinsSemiBold;
    color: $color-white;
    position: absolute;
    bottom: 1.3em;
    right: 1.3em;
    display: block;
    z-index: 2;
    text-transform: uppercase;
  }
}
.project_wrapper.active {
  left: 0;
}

.contact_flex {
  width: 100%;
  max-width: 60%;
  height: 50%;
  position: fixed;
  bottom: -100%;
  left: 0;
  z-index: 12;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  transition: 0.7s;
  transition-delay: 0.2s;
  .contact_flex_right {
    width: 100%;
    max-width: 35%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #e27663;
    .ittimi_img {
      width: 100%;
      max-width: 12.063em;
    }
    p {
      @include descStyleF20L32;
      @include poppinsRegular;
      padding-left: 1em;
      padding-right: 1em;
      text-align: center;
      margin-top: 1em;
    }
  }
  .contact_flex_left {
    width: 100%;
    max-width: 65%;
    height: 100%;
    position: relative;
    .contact_text_container {
      position: absolute;
      bottom: 3.5em;
      right: 4.75em;
      width: 100%;
      text-align: right;
      // padding-right: 4.75em;
      // padding-bottom: 3.5em;
    }
    .get_in_touch {
      margin-bottom: 1.2em;
      font-size: 2.5em;
      @include poppinsMedium;
      color: $color-white;
      text-transform: uppercase;
    }
    .navimg2 {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    .contact_desc {
      font-size: 1.375em;
      color: $color-white;
      @include poppinsRegular;
    }
    p {
      margin-bottom: 1.75em;
      a {
        font-size: 1.375em;
        color: $color-white;
        @include poppinsRegular;
      }
    }
    .social_icons_wrapper {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      max-width: 25.5em;
      margin-left: auto;
      .social_icons_circle {
        align-items: center;
        background-color: #fff;
        border-radius: 50%;
        display: flex;
        height: 2.5em;
        justify-content: center;
        transition: background-color 0.3s;
        width: 2.5em;
        &:hover {
          background-color: #e27663;
        }
        // &:nth-child(4) {
        // transition: none;
        // pointer-events: none;
        // &:hover {
        //   background-color: #fff;
        //   cursor: default;
        // }
        // }
        // }
        img {
          max-width: 1.25em;
          width: 100%;
        }
        .fb_img {
          max-width: 0.82em;
        }
        .twitter_img {
          max-width: 1em;
        }
        .pinterest_img {
          max-width: 1em;
        }
        // .youtube {
        //   width: 100%;
        //   max-width: 2.65em;
        //   pointer-events: none;
        //   cursor: default;
        // .youtube_logo {
        //   max-width: 2.65em;
        //   pointer-events: none;
        // }
      }
    }
  }
}
.contact_flex.active {
  bottom: 0;
  // .contact_text_container,
  // .ittimi_img,
  // p {
  // filter: blur(4px);
  // animation: fade-in 0.2s 0.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
  // }
}
.nav_link_wrapper {
  position: fixed;
  width: 100%;
  max-width: 40%;
  height: 100vh;
  background-color: $color-primary;
  bottom: -100vh;
  right: 0;
  z-index: 11;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  // transition: bottom 0.3s ease-in-out;
  transition: 0.5s;
  transition-delay: 0.15s;
  .nav_links_list {
    margin-left: 7.688em;
    height: 55em;
    overflow-y: hidden;
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .nav_links_list.nav_links_expand {
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background: #ffffff59;
    }
  }
  .link_item {
    @include poppinsSemiBold;
    font-size: 2.75em;
    color: $color-white;
    text-transform: uppercase;
    letter-spacing: 0.11em;
    transition: color 0.3s;
    &:hover {
      color: $color-pink;
    }
  }
  .link_selected {
    color: $color-pink;
  }
  .nav_link_item_wrapper:nth-child(n + 2) {
    margin-top: 3em;
  }
  .product_link_item {
    position: relative;
    cursor: pointer;
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -1.2em;
      width: 0.7em;
      height: 0.4em;
      background-image: url("../../images/general/down-arrow.png");
      background-repeat: no-repeat;
      background-size: contain;
      transform-origin: top;
      transition: all 0.3s;
    }
  }
  .product_nav_link_item_wrapper.product_expand .product_link_item {
    &::after {
      transform: rotate(270deg);
    }
  }
  .product_nav_link_item_wrapper {
    ul {
      padding-left: 5em;
      max-height: 0;
      overflow: hidden;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none;
    }
    ul::-webkit-scrollbar {
      display: none;
    }

    .sub_nav_link_item_wrapper .link_item {
      font-size: 2.5em;
    }
    .sub_nav_link_item_wrapper:nth-child(n + 2) {
      margin-top: 1em;
    }
    .sub_nav_link_item_wrapper:nth-child(1) {
      margin-top: 0.5em;
    }
  }
  .product_nav_link_item_wrapper.product_expand {
    ul {
      max-height: 0;
      overflow: auto;
      animation-name: productsExpandAnim;
      animation-duration: 1s;
      animation-fill-mode: forwards;
    }
  }
}
.nav_link_wrapper.active {
  bottom: 0;
  // .link_item {
  // filter: blur(4px);
  // animation: fade-in 0.2s 0.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
  // }
}
@keyframes productsExpandAnim {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 30em;
  }
}

@media (max-width: 1280px) {
  .nav_link_wrapper {
    // padding: 7.5em 3em 0 0;
    .nav_links_list {
      width: 100%;
      max-width: 100%;
      // height: 100%;
      height: 40em;
      overflow-y: auto;
      margin: 0 0 0 6em;
      &::-webkit-scrollbar-thumb {
        background: $color-white;
      }
    }
  }
}
@media (max-width: 1194px) {
  .navbar_wrapper {
    .social_and_brochure_wrapper {
      margin-left: 1.5em;
    }
  }
  .hamburger_menu {
    right: 1.5em;
  }
}
@media (max-width: 834px) {
  .project_wrapper,
  .contact_flex {
    display: none;
  }
  .nav_link_wrapper {
    max-width: 100%;
    justify-content: center;
    flex-direction: column;
    transition-delay: 0s;

    .nav_links_list {
      margin-left: 0;
      text-align: center;
      overflow-y: auto;
    }
    .mb_contact_details {
      margin-top: 11em;
      margin-bottom: 5em;
      p {
        font-size: 1.4em;
        line-height: 3.214em;
        color: $color-white;
        text-align: center;
        @include poppinsRegular;
      }
      a {
        color: $color-white;
      }
    }
  }
  .nav_link_wrapper.active {
    right: 0;
    top: 0;
  }
  .mb_social_and_brochure_wrapper {
    position: fixed;
    z-index: 12;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $color-primary;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    box-shadow: -1px -3px 6px #000;
    padding: 1.5em 0;
    .brochure_text_wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 4em;
      padding-right: 4em;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        width: 2.5px;
        height: 2.233em;
        background-color: $color-white;
        top: 50%;
        transform: translateY(-50%);
        right: 1.5em;
        transition: all 0.3s;
      }
      &::before {
        content: "";
        position: absolute;
        width: 2.5px;
        height: 2.233em;
        background-color: $color-white;
        top: 50%;
        transform: translateY(-50%);
        left: 1.5em;
        transition: all 0.3s;
      }
    }

    .brochure_text {
      font-size: 1.5em;
      @include poppinsSemiBold;
      color: $color-white;
      text-transform: capitalize;
      padding-left: 0.5em;
      transition: all 0.3s;
    }

    .nav_icons {
      width: 100%;
      max-width: 2em;
    }
  }
}
@media (max-width: 767px) {
  .navbar_wrapper {
    height: 6.5em;
    .social_and_brochure_wrapper {
      display: none;
    }
  }
  .mcm_logo_wrapper {
    top: 2.5%;
    left: 1.5em;
    z-index: 14;
    transform: translateX(0);
    .navbar_mcm_logo {
      max-width: 6.3em;
    }
  }
  .hamburger_menu {
    top: 2.5%;
  }
  .nav_link_wrapper {
    bottom: 0;
    right: -100%;
    .link_item {
      font-size: 2.4em;
      letter-spacing: 2.64px;
      &:hover {
        color: $color-white;
      }
    }
    .nav_link_item_wrapper:nth-child(n + 2) {
      margin-top: 1.208em;
    }
    .nav_links_list {
      // height: 40em;
      height: auto;
      padding-left: 10em;
      padding-left: 7em;
      width: 83%;
    }
    .nav_link_item_wrapper {
      width: fit-content;
    }
    .mb_contact_details {
      width: 83%;
      margin-top: 2em;
      padding-left: 7em;
      p {
        text-align: left;
      }
    }
    .product_nav_link_item_wrapper ul {
      padding-left: 2em;
    }
  }

  .nav_link_wrapper.active {
    right: 0;
    // .mb_contact_details {
    //   filter: blur(4px);
    //   animation: fade-in 0.2s 0.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
    // }
  }
}
// @media (min-width: 2560px) {
//   .project_wrapper {
//     max-width: 86.5em;
//     height: 37.75em;
//     &::after {
//       height: 37.75em;
//     }
//   }
//   .contact_flex {
//     max-width: 86.5em;
//     height: 42.75em;
//   }
//   .nav_link_wrapper {
//     max-width: 55.813em;
//   }
// }
// @media (max-width: 1536px) {
//   .project_wrapper {
//     max-width: 81em;
//     height: 34.75em;
//     &::after {
//       height: 34.75em;
//     }
//   }
//   .contact_flex {
//     max-width: 81em;
//   }
//   .nav_link_wrapper {
//     max-width: 58.813em;
//   }
// }
// @media (max-width: 1440px) {
//   .project_wrapper {
//     max-width: 81.188em;
//     height: 43.75em;
//     &::after {
//       height: 43.75em;
//     }
//   }
//   .contact_flex {
//     max-width: 81.188em;
//     height: 42.75em;
//   }
//   .nav_link_wrapper {
//     max-width: 55.813em;
//   }
// }
// @media (max-width: 1366px) {
//   .project_wrapper {
//     max-width: 81.188em;
//     height: 37.75em;
//     &::after {
//       height: 37.75em;
//     }
//   }
//   .contact_flex {
//     max-width: 81.188em;
//     height: 39.75em;
//   }
//   .nav_link_wrapper {
//     max-width: 55.813em;
//   }
// }
// @media (max-width: 1280px) {
//   .project_wrapper {
//     height: 40.75em;
//     &::after {
//       height: 40.75em;
//     }
//   }
//   .contact_flex {
//     height: 43.75em;
//   }
// }

// ------------------------------------------------------------//
// .menu_wrapper {
//   position: fixed;
//   width: 100%;
//   top: 0;
//   left: 0;
//   z-index: 11;
//   height: 100vh;
//   .menu_main_flex {
//     height: 100%;
//     display: flex;
//     justify-content: space-between;
//     align-items: flex-start;
//   }
//   .main_flex_left {
//     width: 100%;
//     max-width: 60%;
//     height: 100%;
//   }
//   .main_flex_right {
//     width: 100%;
//     max-width: 40%;
//     background-color: $color-primary;
//     position: relative;
//     z-index: -3;
//     // height: 100%;
//   }
//   .projects_img_wrapper {
//     position: relative;

//     .navimg1 {
//       height: 100%;
//       width: 100%;
//       object-fit: cover;
//     }
//     .project_span {
//       font-size: 2.5em;
//       @include poppinsSemiBold;
//       color: $color-white;
//       position: absolute;
//       bottom: 1em;
//       right: 1em;
//       display: block;
//       z-index: 2;
//     }
//   }
//   .contact_flex {
//     display: flex;
//     justify-content: space-between;
//     height: 100%;
//     align-items: flex-start;
//   }
//   .contact_flex_right {
//     width: 100%;
//     max-width: 35%;
//     height: 100%;
//     display: flex;
//     justify-content: flex-start;
//     align-items: center;
//     flex-direction: column;
//     background-color: #e27663;
//     .ittimi_img {
//       width: 100%;
//       max-width: 13.75em;
//     }
//   }
//   .contact_flex_left {
//     width: 100%;
//     max-width: 65%;
//     .navimg2 {
//       width: 100%;
//       object-fit: cover;
//     }
//   }
// }
// @keyframes fade-in {
//   100% {
//     opacity: 1;
//     filter: blur(0);
//   }
// }
