@import "../../common.scss";
.notfoundsec {
  background-color: $color-primary;
  padding: 7.5em;
  position: relative;
  .img_wrapper {
    text-align: right;
  }
  .img_404 {
    width: 100%;
    max-width: 59.563em;
  }
  .text_wrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 7.5em;
    .oops_text {
      display: block;
      font-size: 3.125em;
      line-height: 0.64em;
      @include poppinsRegular;
      color: $color-white;
    }
    .text_404 {
      font-size: 25.063em;
      color: $color-white;
      @include poppinsBold;
      line-height: 1;
      letter-spacing: 0.037em;
    }
    .desc {
      @include descStyleF20L32;
      @include poppinsRegular;
      color: $color-white;
      margin-bottom: 2em;
    }
  }
}
@media (max-width: 1919px) {
  .notfoundsec {
    .text_wrapper {
      .circle_404 {
        width: 100%;
        max-width: 0.7em;
      }
    }
    .img_404 {
      max-width: 78.563em;
    }
  }
}
@media (max-width: 1194px) {
  .notfoundsec {
    padding-left: 0;
    padding-right: 0;
    .text_wrapper {
      left: 1.5em;
    }
    .img_404 {
      max-width: 54.563em;
    }
  }
}
@media (max-width: 834px) {
  .notfoundsec {
    .text_wrapper {
      position: initial;
      top: initial;
      left: initial;
      transform: translateY(0);
      text-align: center;
      .mcm_cta {
        margin: 0 auto;
      }
      .text_404 {
        font-size: 13em;
      }
    }
    .img_404 {
      max-width: 100%;
    }
  }
}
