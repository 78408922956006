@import "../../common.scss";
.blog_img {
  width: 100%;
  object-fit: cover;
}
.blog_details_wrapper {
  padding: 1.438em;
}
.blog_date_and_cat {
  @include textStyles(1em, normal, 0.32px);
  @include poppinsRegular;
  color: #616161;
}
.blog_title {
  @include textStyles(1.625em, 1.385em, 0.52px);
  @include poppinsSemiBold;
  color: #f8f8f8;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.blog_desc {
  @include textStyles(1.125em, 1.556em, 0.36px);
  @include poppinsRegular;
  color: #f8f8f8;
  margin-bottom: 1em;
}
.mcm_cta {
  width: fit-content;
}
@media (max-width: 767px) {
  .blog_date_and_cat {
    font-size: 1.2em;
    letter-spacing: 0.2px;
  }
  .blog_details_wrapper {
    padding: 0.8em;
  }
  .blog_title {
    @include textStyles(2.2em, 1.455em, 0.44px);
    margin-top: 0.5em;
    margin-bottom: 0.455em;
  }
  .blog_desc {
    @include mbF16L26;
  }
}
