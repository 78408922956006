@import "../../common.scss";
.banner_wrapper {
  position: relative;
  height: calc(100vh - 10em);
  .disabled {
    pointer-events: none;
  }
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 10em);
    width: 100%;
    position: relative;
    overflow: hidden;
  }

  // .container:nth-child(1) {
  //   background: #b6916d;
  // }
  .reveal {
    // visibility: hidden;
    position: relative;
    width: 100vw;
    height: 100%;
    // max-width: 400px;
    overflow: hidden;
  }

  .bannerimg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform-origin: left;
  }
  .banner_title {
    @include f120L154;
    @include cocogooseRegular;
    color: $color-white;
    text-transform: uppercase;
    top: 50%;
    left: 1.167em;
    transform: translateY(-50%);
    position: absolute;
    width: 100%;
    max-width: 72%;
    // opacity: 0;
    // filter: blur(4px);
    // animation: fade-in 0.4s 0.75s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  .common_blog_details_wrapper {
    @include textStyles(1.125em, 2.222em, 0.36px);
    @include poppinsRegular;
    position: absolute;
    color: #f8f8f8;
    text-transform: capitalize;
    top: 60%;
    left: 8em;
    transform: translateY(-50%);
  }
  .breadcrumb_wrapper {
    position: absolute;
    top: 7.5em;
    left: 6.25em;
  }
  .breadcrumb_link {
    @include textStyles(1em, 2em, normal);
    @include poppinsRegular;
    color: $color-white;
    text-transform: capitalize;
  }
}

.banner_wrapper.dropshadow {
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.25;
    z-index: 1;
  }
  .banner_title {
    z-index: 2;
  }
  .bannerimg {
    z-index: -1;
  }
  .banner_title {
    text-shadow: 10px 10px 6px #0000004f;
  }
  .breadcrumb_wrapper {
    text-shadow: 10px 7px 6px #00000066;
    background: rgba($color: #1d1d1d, $alpha: 0.8);
    border-radius: 2em;
    padding: 0.75em 3em;
    z-index: 2;
    .breadcrumb_link {
      font-weight: bold;
    }
  }
}
@media (max-width: 834px) {
  .banner_wrapper {
    .breadcrumb_wrapper {
      left: 1.5em;
    }
  }
}
@media (max-width: 767px) {
  .banner_wrapper {
    background-color: $color-primary;
    padding-top: 11.5em;
    padding-bottom: 5em;
    z-index: 2;
    height: initial;
    &::before {
      opacity: 0;
    }
    .banner_title {
      @include textStyles(4.5em, 1.556em, normal);
      position: initial;
      transform: none;
      width: 100%;
      max-width: 90%;
      margin: 0 auto;
      text-align: center;
    }
    .common_blog_details_wrapper {
      // left: 50%;
      // transform: translateX(-50%);
      // width: 100%;
      text-align: center;
      font-size: 1em;
      letter-spacing: 0.2px;
      position: initial;
      // top: 80%;
    }

    .breadcrumb_wrapper {
      // left: 1.5em;
      top: 7.7em;
    }
  }
  .banner_wrapper.dropshadow {
    &::before {
      display: none;
    }
    .breadcrumb_wrapper {
      padding: 0;
    }
  }
}
// @keyframes fade-in {
//   100% {
//     opacity: 1;
//     filter: blur(0);
//   }
// }
