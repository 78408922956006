@import "../../common.scss";
.projectdetailsec2 {
  background-color: $color-primary;
  padding-bottom: 9.375em;
  padding-top: 0;
  .text_container_flex {
    padding-left: 13.125em;
    padding-right: 13.125em;
    padding-bottom: 0;
    padding-top: 5.625em;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .flex_left,
    .flex_right {
      width: 100%;
      max-width: 50%;
    }
    .heading_container {
      height: 3em;
      width: 100%;
      overflow: hidden;
      h3 {
        @include headingStyleF50L45;
        @include poppinsSemiBold;
        color: $color-white;
        position: relative;
        width: fit-content;
        transform: translateY(100px);
        transition: all 0.2s ease-in;
        &::after {
          content: "";
          position: absolute;
          width: 2.54em;
          height: 3px;
          background-color: $color-pink;
          top: 50%;
          transform: translateY(-50%);
          right: -3em;
        }
      }
    }
    .left_para {
      @include textStyles(2.813em, 1.444em, normal);
      @include cocogooseRegular;
      color: $color-greyishWhite;
      width: 100%;
      max-width: 80%;
      margin-top: 1.111em;
    }
    .para_right {
      @include descStyleF20L32;
      @include poppinsRegular;
      color: $color-white;
      padding-top: 9em;
    }
  }
  .project_detail_slider {
    padding-left: 22.625em;
    position: relative;
    .project_detail_img_wrapper {
      width: 100%;
      max-width: 76.625em;
      position: relative;
    }
    .testiplayicon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      max-width: 9.563em;
      cursor: pointer;
    }
    .project_detail_img {
      width: 100%;
      object-fit: cover;
    }
    .hollow_text {
      color: $color-white;
      top: -0.5em;
      position: absolute;
      left: 1.4em;
      z-index: 1;
      // opacity: 0;
      transition: all 0.2s ease-in;
      text-shadow: 10px 10px 6px #0000004f;
    }
    .project_detail_swiper_container {
      z-index: 0;
      // opacity: 0;
      transition: all 0.2s ease-in;
    }
    .swiper-wrapper {
      z-index: 0;
    }
    .nav_arrow_wrapper {
      position: absolute;
      left: 8.75em;
      bottom: 0;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }
    .nav_arrow {
      width: 100%;
      max-width: 4.188em;
      box-sizing: content-box;
    }
    .nav_arrow_right {
      padding-left: 2em;
    }
  }
}
@media (max-width: 1194px) {
  .projectdetailsec2 {
    .text_container_flex {
      padding-left: $pad-left-right30;
      padding-right: $pad-left-right30;
    }
  }
}
@media (max-width: 834px) {
  .projectdetailsec2 {
    padding-bottom: 0;
    .project_detail_slider {
      padding-bottom: 9.9em;
      padding-left: $pad-left-right30;
      padding-right: $pad-left-right30;
      .project_detail_img_wrapper {
        max-width: 100%;
      }
      .hollow_text {
        left: 0.15em;
      }
      .nav_arrow_wrapper {
        left: 50%;
        transform: translateX(-50%);
        bottom: 5em;
      }
    }
  }
}
@media (max-width: 767px) {
  .projectdetailsec2 {
    .text_container_flex {
      padding-top: 0;
      padding-bottom: 5.5em;
      flex-direction: column;
      .flex_left,
      .flex_right {
        max-width: 100%;
      }
      h3 {
        @include mbF16L45;
      }
      .left_para {
        max-width: 100%;
        font-size: 2.4em;
        line-height: 1.458em;
        margin-top: 0.833em;
      }
      .para_right {
        @include mbF16L26;
        max-width: 100%;
        padding-top: 1.875em;
      }
    }
    .project_detail_slider {
      .project_detail_img {
        min-height: 22.6em;
        object-fit: cover;
      }
      .testiplayicon {
        max-width: 5.563em;
      }
      .nav_arrow {
        max-width: 3.188em;
      }
    }
  }
}
