@import "../../common.scss";
.downloadssec2 {
  padding: 5.625em 8.125em;
  background-color: $color-primary;
  .downloads_flex {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    // opacity: 0;
    transition: all 0.2s ease-in;
  }
  .download_box {
    width: 100%;
    max-width: 30%;
    position: relative;
    &:nth-child(2) {
      margin: 0 4em;
    }
    a {
      display: block;
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 3;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background-image: linear-gradient(
          rgba(255, 0, 0, 0) 70%,
          rgba(65, 61, 61, 0.5)
        );
        width: 100%;
        height: 100%;
        transition: opacity 0.3s;
      }
    }
  }

  .download_box:nth-child(n + 4) {
    margin-top: 3.125em;
  }
  .download_img {
    width: 100%;
    object-fit: cover;
  }
  h5 {
    @include textStyles(1.375em, 1.591em, 0.55px);
    @include poppinsSemiBold;
    position: absolute;
    bottom: 0.682em;
    left: 0.682em;
    right: 3.5em;
    color: #f8f8f8;
    z-index: 2;
  }
  .download_icon {
    position: absolute;
    width: 100%;
    max-width: 2.375em;
    right: 0.938em;
    bottom: 0.938em;
    z-index: 2;
  }
  .load_more_wrapper {
    margin-top: 5em;
    text-align: center;
  }
}
@media (max-width: 1194px) {
  .downloadssec2 {
    padding-left: 1.5em;
    padding-right: 1.5em;
  }
}
@media (max-width: 992px) {
  .downloadssec2 {
    .download_box {
      max-width: 29%;
    }
  }
}
@media (max-width: 600px) {
  .downloadssec2 {
    padding-top: 0;
    .download_box {
      max-width: 100%;
      &:nth-child(2) {
        margin: 0;
      }
    }
    .download_box:nth-child(n + 4) {
      margin-top: 0;
    }
    .download_box:nth-child(n + 2) {
      margin-top: 3em;
    }
    h5 {
      font-size: 1.6em;
    }
    .download_icon {
      bottom: 1.5em;
    }
  }
}
