@import "../../common.scss";
.projectssec2 {
  padding-top: 5.625em;
  padding-bottom: 7.5em;
  background-color: $color-primary;
  .desc {
    @include descStyleF20L32;
    @include poppinsRegular;
    color: $color-white;
    text-align: center;
    width: 100%;
    max-width: 78%;
    margin: 0 auto;
    // opacity: 0;
    transition: all 0.2s ease-in;
  }
  .projects_category_flex {
    padding-left: 11.875em;
    padding-right: 8.75em;
    padding-top: 9.313em;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    // opacity: 0;
    transition: all 0.2s ease-in;
    .project_category {
      width: 100%;
      max-width: 47%;
      position: relative;
      cursor: pointer;
    }
    .projects_category_img {
      width: 100%;
      object-fit: cover;
    }
    .hollow_text {
      @include textStyles(4.375em, normal, normal);
      position: absolute;
    }
    .project_category:nth-child(odd) .hollow_text {
      top: -0.7em;
      left: -1.82em;
    }
    .project_category:nth-child(even) .hollow_text {
      top: -0.7em;
      right: -0.9em;
    }
    .project_category:nth-child(even) {
      margin-top: 10em;
    }
  }
}
@media (max-width: 1194px) {
  .projectssec2 {
    .projects_category_flex {
      padding-left: $pad-left-right;
      padding-right: $pad-left-right;
      .project_category:nth-child(even) .hollow_text {
        right: 0;
      }
      .project_category:nth-child(odd) .hollow_text {
        left: 0;
      }
    }
  }
}
@media (max-width: 834px) {
  .projectssec2 {
    .projects_category_flex {
      .hollow_text {
        font-size: 3em;
      }
    }
  }
}

@media (max-width: 767px) {
  .projectssec2 {
    padding-bottom: 10em;
    padding-top: 0;
    .desc {
      max-width: 90%;
      @include mbF16L26;
    }
    .projects_category_flex {
      padding-top: 8.2em;
      padding-left: $pad-left-right30;
      padding-right: $pad-left-right30;
      .project_category {
        max-width: 100%;
      }
      .hollow_text {
        font-size: 3.2em;
        width: 50%;
      }
      .project_category:nth-child(even) {
        margin-top: 0;
      }
      .project_category:nth-child(even) .hollow_text {
        right: -0.1em;
        text-align: right;
      }
      .project_category:nth-child(odd) .hollow_text {
        left: -0.3em;
      }
      .project_category:nth-child(n + 2) {
        margin-top: 8.5em;
      }
    }
  }
}
@media (max-width: 330px) {
  .projectssec2 {
    .projects_category_flex {
      .project_category:nth-child(even) .hollow_text {
        right: 0.5em;
      }
    }
  }
}
