.loader_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 50;
    background-color: #fff;
    .loader_img {
      width: 100%;
      max-width: 9.2em;
    }
  }