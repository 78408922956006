@import "../../common.scss";
.thankyousec {
  padding-top: 6.25em;
  background-color: $color-primary;
  position: relative;
  .thankyouimg_wrapper {
    text-align: right;
    img {
      width: 100%;
      max-width: 75%;
    }
  }
  .text_wrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 4.75em;
    h1 {
      @include cocogooseRegular;
      font-size: 8.75em;
      line-height: 1.1em;
      width: 100%;
      max-width: 90%;
      text-transform: uppercase;
      color: $color-white;
    }
    .desc {
      @include descStyleF20L32;
      @include poppinsRegular;
      color: $color-white;
      width: 100%;
      max-width: 56%;
      margin-bottom: 1em;
    }
  }
}
// @media (max-width: 1536px) {
//   .thankyousec {
//     .thankyouimg_wrapper {
//       img {
//         max-width: 109.063em;
//       }
//     }
//   }
// }
// @media (max-width: 1367px) {
//   .thankyousec {
//     .thankyouimg_wrapper {
//       img {
//         max-width: 106.063em;
//       }
//     }
//   }
// }
@media (max-width: 1194px) {
  .thankyousec {
    .text_wrapper {
      left: 1.5em;
    }
  }
}
@media (max-width: 767px) {
  .thankyousec {
    padding-top: 10em;
    .text_wrapper {
      position: initial;
      left: initial;
      top: initial;
      transform: translateY(0);
      text-align: center;
      padding-left: 1.5em;
      padding-right: 1.5em;
      h1 {
        font-size: 4.5em;
        width: 100%;
        max-width: 100%;
      }
      .desc {
        font-size: 1.6em;
        max-width: 100%;
        margin-bottom: 1em;
        margin-top: 0.5em;
      }
      .mcm_cta {
        margin: 0 auto;
        margin-bottom: 2em;
      }
    }
    .thankyouimg_wrapper {
      img {
        max-width: 100%;
      }
    }
  }
}
