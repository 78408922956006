/* font-family: 'Poppins', sans-serif; */
@font-face {
  font-family: "cocogoose-ultra-light-italic";
  src: url("./fonts/CocogoosePro-UltraLightItalic.eot");
  src: url("./fonts/CocogoosePro-UltraLightItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/CocogoosePro-UltraLightItalic.woff2") format("woff2"),
    url("./fonts/CocogoosePro-UltraLightItalic.woff") format("woff"),
    url("./fonts/CocogoosePro-UltraLightItalic.ttf") format("truetype"),
    url("./fonts/CocogoosePro-UltraLightItalic.svg#CocogoosePro-UltraLightItalic")
      format("svg");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "cocogoose-demibold";
  src: url("./fonts/COCOGOOSE-DemiBold.eot");
  src: url("./fonts/COCOGOOSE-DemiBold.eot?#iefix") format("embedded-opentype"),
    url("./fonts/COCOGOOSE-DemiBold.woff2") format("woff2"),
    url("./fonts/COCOGOOSE-DemiBold.woff") format("woff"),
    url("./fonts/COCOGOOSE-DemiBold.ttf") format("truetype"),
    url("./fonts/COCOGOOSE-DemiBold.svg#COCOGOOSE-DemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "cocogoose-thin";
  src: url("./fonts/CocogoosePro-Thin.eot");
  src: url("./fonts/CocogoosePro-Thin.eot?#iefix") format("embedded-opentype"),
    url("./fonts/CocogoosePro-Thin.woff2") format("woff2"),
    url("./fonts/CocogoosePro-Thin.woff") format("woff"),
    url("./fonts/CocogoosePro-Thin.ttf") format("truetype"),
    url("./fonts/CocogoosePro-Thin.svg#CocogoosePro-Thin") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "cocogoose-thinitalic";
  src: url("./fonts/CocogoosePro-ThinItalic.eot");
  src: url("./fonts/CocogoosePro-ThinItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/CocogoosePro-ThinItalic.woff2") format("woff2"),
    url("./fonts/CocogoosePro-ThinItalic.woff") format("woff"),
    url("./fonts/CocogoosePro-ThinItalic.ttf") format("truetype"),
    url("./fonts/CocogoosePro-ThinItalic.svg#CocogoosePro-ThinItalic")
      format("svg");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "cocogoose-regular";
  src: url("./fonts/Cocogoose.eot");
  src: url("./fonts/Cocogoose.eot?#iefix") format("embedded-opentype"),
    url("./fonts/Cocogoose.woff2") format("woff2"),
    url("./fonts/Cocogoose.woff") format("woff"),
    url("./fonts/Cocogoose.ttf") format("truetype"),
    url("./fonts/Cocogoose.svg#Cocogoose") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "cocogoose-italic";
  src: url("./fonts/CocogoosePro-Italic.eot");
  src: url("./fonts/CocogoosePro-Italic.eot?#iefix") format("embedded-opentype"),
    url("./fonts/CocogoosePro-Italic.woff2") format("woff2"),
    url("./fonts/CocogoosePro-Italic.woff") format("woff"),
    url("./fonts/CocogoosePro-Italic.ttf") format("truetype"),
    url("./fonts/CocogoosePro-Italic.svg#CocogoosePro-Italic") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "cocogoose-lightitalic";
  src: url("./fonts/CocogoosePro-LightItalic.eot");
  src: url("./fonts/CocogoosePro-LightItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/CocogoosePro-LightItalic.woff2") format("woff2"),
    url("./fonts/CocogoosePro-LightItalic.woff") format("woff"),
    url("./fonts/CocogoosePro-LightItalic.ttf") format("truetype"),
    url("./fonts/CocogoosePro-LightItalic.svg#CocogoosePro-LightItalic")
      format("svg");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "cocogoose-ultralight";
  src: url("./fonts/COCOGOOSE-UltraLight.eot");
  src: url("./fonts/COCOGOOSE-UltraLight.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/COCOGOOSE-UltraLight.woff2") format("woff2"),
    url("./fonts/COCOGOOSE-UltraLight.woff") format("woff"),
    url("./fonts/COCOGOOSE-UltraLight.ttf") format("truetype"),
    url("./fonts/COCOGOOSE-UltraLight.svg#COCOGOOSE-UltraLight") format("svg");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "cocogoose-light";
  src: url("./fonts/Cocogoose-Light.eot");
  src: url("./fonts/Cocogoose-Light.eot?#iefix") format("embedded-opentype"),
    url("./fonts/Cocogoose-Light.woff2") format("woff2"),
    url("./fonts/Cocogoose-Light.woff") format("woff"),
    url("./fonts/Cocogoose-Light.ttf") format("truetype"),
    url("./fonts/Cocogoose-Light.svg#Cocogoose-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "cocogoose-semilight-italic";
  src: url("./fonts/CocogoosePro-SemiLightItalic.eot");
  src: url("./fonts/CocogoosePro-SemiLightItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/CocogoosePro-SemiLightItalic.woff2") format("woff2"),
    url("./fonts/CocogoosePro-SemiLightItalic.woff") format("woff"),
    url("./fonts/CocogoosePro-SemiLightItalic.ttf") format("truetype"),
    url("./fonts/CocogoosePro-SemiLightItalic.svg#CocogoosePro-SemiLightItalic")
      format("svg");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
