@import "../../common.scss";
.productssec2 {
  padding-top: 5.625em;
  padding-bottom: 10em;
  background-color: $color-primary;
  .products_desc {
    @include descStyleF20L32;
    @include poppinsRegular;
    width: 100%;
    max-width: 80%;
    margin: 0 auto;
    text-align: center;
    color: $color-white;
    margin-bottom: 3.3em;
    // opacity: 0;
    transition: all 0.2s ease-in;
  }
  .product_category_flex {
    padding: 0 21em;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    // opacity: 0;
    transition: all 0.2s ease-in;
    .category_name_wrapper {
      width: 100%;
      max-width: 32%;
      text-align: center;
      position: relative;
    }
    .category_name_wrapper:nth-child(n + 4) {
      margin-top: 1em;
    }
    .category_name_wrapper::after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      width: 2px;
      height: 80%;
      background-color: $color-pink;
    }
    .category_name_wrapper:nth-child(3n + 3)::after {
      display: none;
    }
    .category_name_wrapper:last-child::after {
      display: none;
    }
    .category_name {
      @include headingStyleF50L45;
      @include poppinsSemiBold;
      color: #ffffff59;
      cursor: pointer;
      width: 100%;
      max-width: 5.86em;
      text-align: center;
      padding: 0.4em 0.1em;
      display: inline-block;
      position: relative;
      transition: all 0.2s ease-in-out;
    }

    .category_name.active {
      background-color: $color-primary;
      box-shadow: 0.18em 0.18em 0.12em #00000042;
      color: $color-white;
    }

    .category_name:nth-child(n + 4) {
      margin-top: 0.6em;
    }
  }

  .category_info_flex {
    display: none;
    padding-top: 7.5em;
    padding-left: 10.938em;
    padding-right: 8.75em;
    padding-bottom: 9.375em;
    justify-content: space-between;
    align-items: flex-start;
    .category_info_left {
      width: 100%;
      max-width: 50%;
      h3 {
        @include headingStyleF50L45;
        @include poppinsSemiBold;
        color: $color-white;
        position: relative;
        width: fit-content;
        &::after {
          content: "";
          position: absolute;
          width: 2.54em;
          height: 3px;
          background-color: $color-pink;
          top: 50%;
          transform: translateY(-50%);
          right: -3em;
        }
      }
      p {
        @include descStyleF20L32;
        @include poppinsRegular;
        color: $color-white;
        width: 100%;
        max-width: 90%;
        margin-top: 2.15em;
      }
    }
    .category_info_right {
      width: 100%;
      max-width: 50%;
      text-align: right;
      position: relative;
      padding-top: 7em;
      .product_img {
        width: 100%;
        max-width: 54.438em;
        height: auto;
      }
      .hollow_text {
        position: absolute;
        display: inline-block;
        width: 28%;
        top: 0;
        right: 0;
      }
    }
  }
  .category_info_flex.active {
    display: flex;
  }
  .product_select_flex {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 23.313em;
    margin: 0 auto;
    box-shadow: 9px 9px 6px #00000042;
    // opacity: 0;
    transition: all 0.2s ease-in;
  }
  .choose_color_wrapper {
    width: 100%;
    max-width: 23.313em;
    position: relative;
  }
  .category_dropdown {
    @include textStyles(2em, normal, normal);
    @include poppinsSemiBold;
    padding: 0.958em 0.5em;
    text-align: center;
    color: $color-white;
    text-transform: uppercase;
    cursor: pointer;
    position: relative;
    background-color: $color-primary;
    border: none;
    width: 100%;
  }
  .down_arrow_img {
    width: 100%;
    max-width: 1.5em;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 3em;
    transition: 0.3s transform;
    z-index: 1;
  }
  .choose_color {
    @include textStyles(1.5em, normal, normal);
    @include poppinsBold;
    padding: 0.958em 0.5em;
    text-align: center;
    color: $color-white;
    background-color: $color-primary;
    border: none;
    text-transform: capitalize;
    cursor: pointer;
    position: relative;
    width: 100%;
    // background: url("../../images/general/down-arrow.png") 94% / 3% no-repeat;
    // background-color: $color-primary;
    .down_arrow_img {
      width: 100%;
      max-width: 0.688em;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 1em;
      transition: 0.3s transform;
    }
  }
  .choose_color.choose_color_active {
    .down_arrow_img {
      transform: translateY(-50%) rotate(-90deg);
    }
  }
  .color_list {
    position: absolute;
    margin-top: 0.5em;
    max-height: 0;
    width: 100%;
    z-index: 2;
    background-color: $color-primary;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background: #ffffff59;
    }
    li {
      text-align: center;
      @include textStyles(1.5em, normal, normal);
      @include poppinsBold;
      padding: 0.958em 0.5em;
      color: $color-white;
      text-transform: capitalize;
      cursor: pointer;
      transition: background-color 0.3s;
      &:hover {
        background-color: #ffffff59;
      }
    }
    li.colo_active {
      background-color: #ffffff59;
    }
  }
  .color_list.color_list_active {
    max-height: 31.688em;
    animation-name: colorListAnim;
    animation-duration: 1s;
  }

  .product_card_flex {
    padding: 6.25em 18.188em;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    // opacity: 0;
    transition: all 0.2s ease-in;
    .product_card {
      width: 100%;
      max-width: 24.063em;
      position: relative;
      overflow: hidden;
      transition: box-shadow 0.3s;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background-image: linear-gradient(
          rgba(255, 0, 0, 0) 70%,
          rgba(65, 61, 61, 0.5)
        );
        width: 100%;
        height: 100%;
        transition: opacity 0.3s;
        opacity: 0;
      }
    }

    .product_card:nth-child(3n + 2) {
      margin-top: 5.625em;
    }
    .product_img {
      width: 100%;
    }
    span {
      @include textStyles(1.5em, 1.875em, 0.48px);
      @include poppinsSemiBold;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -50%;
      color: $color-white;
      transition: bottom 0.3s;
      z-index: 2;
      text-transform: capitalize;
    }
    .product_card:hover span {
      bottom: 1.25em;
    }
    .product_card:hover {
      box-shadow: 0px 8px 6px #00000080;
    }
    .product_card:hover::after {
      opacity: 1;
    }
  }
  .load_more_wrapper {
    text-align: center;
  }
}
@media (min-width: 2560px) {
  .productssec2 {
    .product_category_flex {
      padding-left: 30em;
      padding-right: 30em;
    }
  }
}
@media (max-width: 1600px) {
  .productssec2 {
    .product_category_flex {
      padding-left: 28em;
      padding-right: 28em;
    }
  }
}
@media (max-width: 1560px) {
  .productssec2 {
    .product_card_flex {
      .product_card {
        max-width: 28.063em;
      }
    }
  }
}
@media (max-width: 1194px) {
  .productssec2 {
    padding-top: 9.875em;
    padding-left: $pad-left-right;
    padding-right: $pad-left-right;
    .product_category_flex {
      padding-left: 0;
      padding-right: 0;
    }
    .category_info_flex {
      padding-left: 0;
      padding-right: 0;
      .category_info_right {
        .hollow_text {
          width: 100%;
        }
      }
    }
    .products_desc {
      max-width: 100%;
    }
    .product_card_flex {
      padding-left: 1.5em;
      padding-right: 1.5em;
    }
  }
}
@media (max-width: 834px) {
  .productssec2 {
    .product_card_flex {
      .product_card {
        max-width: 22.063em;
        &::after {
          opacity: 1;
        }
      }
      span {
        bottom: 1.25em;
        width: 100%;
        text-align: center;
      }
    }
  }
}
@media (max-width: 767px) {
  .productssec2 {
    padding-top: 0;
    padding-bottom: 8em;
    .products_desc {
      @include mbF16L26;
      margin-bottom: 1.625em;
    }
    .mb_choose_color_wrapper {
      margin: 0 auto;
      box-shadow: 9px 9px 6px #00000042;
    }
    .category_info_flex {
      padding-top: 4em;
      padding-bottom: 8em;
      flex-direction: column;
      .category_info_left,
      .category_info_right {
        max-width: 100%;
      }
      .category_info_left {
        h3 {
          @include mbF16L45;
        }
        p {
          @include mbF16L26;
          max-width: 100%;
          margin-top: 0.4em;
          margin-bottom: 1.875em;
        }
      }
      .category_info_right {
        padding-top: 1em;
        text-align: center;
        .product_img {
          max-width: 90%;
        }
        .hollow_text {
          width: initial;
          top: -0.3em;
        }
      }
    }
    .product_select_flex {
      flex-direction: column;
      max-width: 23.4em;
      // padding-top: 2em;
      // padding-bottom: 2em;
    }
    .mb_product_select_choose_color_wrapper {
      max-width: 100%;
    }
    .product_card_flex {
      padding-top: 7.5em;
      padding-bottom: 4em;
      .product_card {
        max-width: 100%;
        &::after {
          background-image: linear-gradient(
            rgba(255, 0, 0, 0) 60%,
            rgba(65, 61, 61, 0.8)
          );
        }
      }
      .product_card:nth-child(3n + 2) {
        margin-top: 0;
      }
      .product_card:nth-child(n + 2) {
        margin-top: 4em;
      }
      span {
        @include textStyles(1.9em, normal, 0.38px);
        bottom: 1.563em;
        padding-left: 1.5em;
        padding-right: 1.5em;
      }
    }
  }
}

// keyframes
@keyframes colorListAnim {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 31.688em;
  }
}
