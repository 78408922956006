@import "../../common.scss";
.blogdetailsec2 {
  background-color: $color-primary;
  padding: 5.625em 8.75em 9.375em;
  .blog_desc {
    @include descStyleF20L32;
    @include poppinsRegular;
  }
}
.blogdetailsec3 {
  background-color: $color-primary;
  h3 {
    @include headingStyleF50L45;
    @include poppinsSemiBold;
    position: relative;
    width: fit-content;
    margin: 0 auto;
    color: $color-white;
    &::after {
      content: "";
      position: absolute;
      width: 4.233em;
      height: 2.5px;
      background-color: $color-pink;
      top: 50%;
      transform: translateY(-50%);
      right: -5em;
    }
    &::before {
      content: "";
      position: absolute;
      width: 4.233em;
      height: 2.5px;
      background-color: $color-pink;
      top: 50%;
      transform: translateY(-50%);
      left: -5em;
    }
  }
  .more_articles_slider {
    padding-left: 6.25em;
    padding-right: 6.25em;
    padding-top: 5em;
    padding-bottom: 9.375em;
    .swiper-slide {
      padding-bottom: 1em;
    }
    .blogdetails_wrapper_slide {
      width: 100%;
      max-width: 31.625em;
      box-shadow: 9px 9px 6px #00000042;
      margin: 0 auto;
    }
    .nav_arrow_wrapper {
      text-align: center;
      margin-top: 3em;
      .nav_arrow {
        width: 100%;
        max-width: 4.188em;
        box-sizing: content-box;
      }
      .nav_arrow_right {
        padding-left: 2em;
      }
    }
  }
}
@media (max-width: 1194px) {
  .blogdetailsec2 {
    padding-left: 1.5em;
    padding-right: 1.5em;
  }
  .blogdetailsec3 {
    .more_articles_slider {
      padding-left: 1.5em;
      padding-right: 1.5em;
    }
  }
}
@media (max-width: 767px) {
  .blogdetailsec2 {
    padding-top: 0;
    padding-bottom: 6em;
    text-align: center;
    .blog_desc {
      @include mbF16L26;
      @include poppinsRegular;
    }
  }
  .blogdetailsec3 {
    h3 {
      @include mbF16L45;
      @include poppinsSemiBold;
      &::before {
        left: -3em;
      }
      &::after {
        right: -3em;
      }
      &::after,
      &::before {
        width: 2.25em;
      }
    }
    .more_articles_slider {
      padding-top: 3em;
      .blogdetails_wrapper_slide {
        max-width: 100%;
      }
    }
  }
}
