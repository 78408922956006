@import "../../common.scss";
// * {
//   outline: 1px solid orangered;
// }
.bar {
  transform-origin: left;
  transition: transform 0.25s ease-out;
}

.bar.active {
  transform: scaleX(1);
}
// .reveal-text[data-initial="hidden"] {
//   visibility: hidden;

//   &.animation-initialized {
//     visibility: visible;
//   }
// }
.homesec1 {
  position: relative;
  // overflow: hidden;
  overflow-y: hidden;
  height: 100vh;
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    position: relative;
    overflow: hidden;
  }

  // .container:nth-child(1) {
  //   background: #b6916d;
  // }
  .reveal {
    // visibility: hidden;
    position: relative;
    width: 100vw;
    height: 100%;
    // max-width: 400px;
    overflow: hidden;
  }

  .banner_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform-origin: left;
  }
  .text_conatiner {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    // width: 100%;
    left: 8.75em;
    .products_name_flex {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      // opacity: 0;
      // filter: blur(4px);
      // animation: fade-in 0.4s 0.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
      span {
        text-transform: uppercase;
        @include textStyles(1.875em, 0.8em, normal);
        @include poppinsRegular;
        color: $color-white;
        padding: 0 1.2em 0 0;
        .inside_link {
          color: $color-white;
        }
        &:nth-child(2) {
          padding: 0 1.2em;
          border-left: 3px solid $color-white;
          border-right: 3px solid $color-white;
        }
        &:nth-child(3) {
          padding: 0 0 0 1.2em;
        }
      }
    }
    h1 {
      @include f120L154;
      @include cocogooseRegular;
      color: $color-white;
      text-transform: uppercase;
      margin-top: 0.3em;
      margin-bottom: 0.3em;
      width: 100%;
      max-width: 85%;
      text-align: left;
      // transform: scale(0.94);
      // animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
      // span {
      //   display: inline-block;
      //   opacity: 0;
      //   filter: blur(4px);
      // }

      // span:nth-child(1) {
      //   animation: fade-in 0.8s 0.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
      // }

      // span:nth-child(2) {
      //   animation: fade-in 0.8s 1s forwards cubic-bezier(0.11, 0, 0.5, 0);
      // }

      // span:nth-child(3) {
      //   animation: fade-in 0.8s 1.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
      // }
    }
    .watch_video_flex {
      visibility: hidden;
      width: 100%;
      max-width: 22em;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .watch_text {
        @include textStyles(1.875em, 0.8em, normal);
        @include poppinsRegular;
        color: $color-white;
      }
      .watch_icon_wrapper {
        border-radius: 50%;
        border: 1px solid $color-pink;
        width: 3.938em;
        height: 3.938em;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      .watch_triangle {
        width: 100%;
        max-width: 1.125em;
      }
    }
  }
  .scroll_down {
    position: absolute;
    bottom: 1.2em;
    left: 50%;
    // border-top: 1px solid $color-white;
    color: $color-white;
    @include textStyles(1.5em, 1.875em, normal);
    @include poppinsRegular;
    font-style: italic;
    // padding: 0 1.5em;
    transform: translateX(-50%) rotate(-90deg);
    cursor: pointer;
    rotate: 90deg;
    .scroll_gif {
      max-width: 1em;
    }
  }
}
.homesec2 {
  background-color: $color-primary;
  padding-top: 5.625em;
  .aboutsec {
    padding: 0 13.125em;
    .about_flex {
      display: flex;
    }
    .about_flex_left {
      width: 100%;
      max-width: 45%;
    }
    .about_flex_right {
      width: 100%;
      max-width: 55%;
    }
    .heading_container {
      height: 3em;
      overflow: hidden;
      h3 {
        @include textStyles(1.875em, 1.5em, 3.3px);
        @include poppinsSemiBold;
        text-transform: uppercase;
        color: $color-white;
        position: relative;
        width: fit-content;
        // transform: translateY(50px);
        &::after {
          content: "";
          position: absolute;
          width: 4.233em;
          height: 2.5px;
          background-color: $color-pink;
          top: 50%;
          // transform: translateY(-50%);
          right: -5em;
        }
      }
    }
    .left_para_container {
      height: 11em;
      overflow: hidden;
      .left_para {
        @include textStyles(2.813em, 1.444em, normal);
        @include cocogooseRegular;
        color: $color-greyishWhite;
        width: 100%;
        max-width: 86%;
        margin-top: 1.111em;
        // transform: translateY(120px);
      }
    }
    .para_right {
      @include descStyleF20L32;
      @include poppinsRegular;
      color: $color-white;
      padding-top: 7em;
      // opacity: 0;
      transition: all 0.2s ease-in;
    }
  }
  .productsec {
    padding: 8.125em 7.75em 5.063em;
    .productsec_content {
      position: relative;
      // opacity: 0;
      transition: all 0.2s ease-in;
    }
    .mcm_cta {
      justify-content: flex-end;
      margin-top: 0.7em;
      margin-left: auto;
    }
    .hollow_text {
      position: absolute;
      right: -0.7em;
      top: -0.65em;
      z-index: 3;
    }
    .product_img_wrapper {
      position: relative;
      overflow: hidden;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(
          to right,
          rgba(0, 0, 0, 0.4) 10%,
          rgba(255, 0, 0, 0) 90%
        );
      }
    }
    .product_img {
      width: 100%;
      height: auto;
      max-height: 45em;
    }

    .btn_wrapper {
      text-align: right;
    }
    .text_container {
      position: absolute;
      bottom: 11.313em;
      left: 3.688em;
      z-index: 1;
      h3 {
        @include headingStyleF50L45;
        @include poppinsSemiBold;
        color: $color-white;
        position: relative;
        width: fit-content;
        &::after {
          content: "";
          position: absolute;
          width: 2.54em;
          height: 3px;
          background-color: $color-pink;
          top: 50%;
          transform: translateY(-50%);
          right: -3em;
        }
      }
      p {
        @include descStyleF20L32;
        @include poppinsRegular;
        color: $color-white;
        margin-top: 1.6em;
        width: 100%;
        max-width: 54%;
      }
    }
    .product_names_swiper_container {
      .swiper-slide-active {
        color: #fff;
      }
    }
    .products_name_flex {
      position: absolute;
      z-index: 2;
      bottom: 0.2em;
      left: -0.5em;
      width: 100%;
      max-width: 70em;
      min-height: 7.375em;
      // background-image: url("../../images/home/product-bg.svg");
      // filter: blur(13px);
      // background-size: cover;
      // background-repeat: no-repeat;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      span {
        cursor: pointer;
        @include textStyles(1.5em, 1.875em, normal);
        @include poppinsBold;
        text-transform: uppercase;
        color: $color-white;
        opacity: 0.4;
        transition: 0.3s opacity;
        a {
          color: $color-white;
        }
      }
      span.product_name_active {
        opacity: 1;
      }
      .middle_product_name {
        position: relative;
        &::before,
        &::after {
          content: "";
          position: absolute;
          height: 3em;
          width: 2.2px;
          background-color: $color-greyishWhite;
          opacity: 0.4 !important;
        }
        &::before {
          top: -0.5em;
          left: -2.5em;
        }
        &::after {
          right: -2.5em;
          top: -0.5em;
        }
      }
      .blur_background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(#1d1d1d6e, #1d1d1d6e),
          url("../../images/home/product-bg.svg");
        background-size: cover;
        background-repeat: no-repeat;
        z-index: -1;
        &::after {
          width: 100%;
          height: 100%;
          z-index: 1;
          $negativeMargin: -20px;
          content: "";
          z-index: -1;
          position: absolute;
          // top: $negativeMargin;
          // right: $negativeMargin;
          // left: $negativeMargin;
          // bottom: $negativeMargin;
          background: inherit;
          // opacity: ;
          // filter: blur(1px);
          // box-shadow: inset 0 0 0 31.25em rgba(255, 255, 255, 0.5);
          // box-shadow: inset 0 0 0 31.25em rgba(29, 29, 29, 0.43);
          // box-shadow: inset 0 0 0 31.25em rgba(255, 255, 255, 0.36);
        }
      }
    }
  }
}

.product {
  padding: 8.125em 7.75em 5.063em;
  position: relative;
  .product_hollow_text {
    position: absolute;
    top: 6%;
    right: 2%;
  }
  .product_img_1 {
    width: 100%;
    height: auto;
  }
  .middle_product_name_1 {
    position: relative;
    &::before,
    &::after {
      content: "";
      position: absolute;
      height: 3em;
      width: 2.2px;
      background-color: $color-greyishWhite;
      opacity: 0.4 !important;
    }
    &::before {
      top: -0.5em;
      left: -2.5em;
    }
    &::after {
      right: -2.5em;
      top: -0.5em;
    }
  }
  .text_container_1 {
    position: absolute;
    bottom: 16.313em;
    left: 12.688em;
    z-index: 1;

    h3 {
      font-size: 3.125em;
      line-height: 0.9em;
      letter-spacing: 5.5px;
      text-transform: uppercase;
      font-family: "Poppins", sans-serif;
      font-weight: 600;
      color: #ffffff;
      position: relative;
      width: -moz-fit-content;
      width: fit-content;

      &::after {
        content: "";
        position: absolute;
        width: 2.54em;
        height: 3px;
        background-color: #e27663;
        top: 50%;
        transform: translateY(-50%);
        right: -3em;
      }
    }
    p {
      @include descStyleF20L32;
      @include poppinsRegular;
      color: $color-white;
      margin-top: 1.6em;
      width: 100%;
      max-width: 54%;
    }
  }
  .product_switch {
    position: absolute;
    z-index: 2;
    bottom: 5.2em;
    left: 8em;
    width: 100%;
    max-width: 70em;
    min-height: 7.375em;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background: linear-gradient(#1d1d1d6e, #1d1d1d6e),
      url("../../images/home/product-bg.svg");
    background-size: cover;
    background-repeat: no-repeat;
    &::after {
      width: 100%;
      height: 100%;
      z-index: 1;
      $negativeMargin: -20px;
      content: "";
      z-index: -1;
      position: absolute;
      background: inherit;
    }
  }
  .product_name {
    font-size: 1.5em;
    line-height: 1.875em;
    letter-spacing: normal;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    color: $color-white;
    opacity: 0.4;
  }
  .product_btn {
    display: flex;
    justify-content: flex-end;
    margin-top: 0.7em;
    margin-left: auto;
  }
}

.homesec3 {
  padding-top: 4.938em;
  padding-bottom: 4.938em;
  .text_container {
    text-align: center;
    // opacity: 0;
    transition: all 0.2s ease-in;
    h3 {
      @include headingStyleF50L45;
      @include poppinsBold;
      position: relative;
      width: fit-content;
      margin: 0 auto;
      &::after {
        content: "";
        position: absolute;
        width: 4.233em;
        height: 2.5px;
        background-color: $color-pink;
        top: 50%;
        transform: translateY(-50%);
        right: -5em;
      }
      &::before {
        content: "";
        position: absolute;
        width: 4.233em;
        height: 2.5px;
        background-color: $color-pink;
        top: 50%;
        transform: translateY(-50%);
        left: -5em;
      }
    }
    .desc {
      @include descStyleF20L32;
      @include poppinsMedium;

      margin: 0 auto;
      width: 100%;
      max-width: 68%;
      margin-top: 2em;
      margin-bottom: 3em;
    }
  }
  .why_flex {
    // opacity: 0;
    padding: 0 19.5em;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;

    .flex_circle {
      width: 100%;
      max-width: 100%;
      text-align: center;
      margin-top: 4em;
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        margin-top: 0em;
      }
      // &:nth-child(3) {
      //   margin-top: 4em;
      // }
      // &:nth-child(4) {
      //   margin-top: 6em;
      // }
      // &:nth-child(6) {
      //   margin-top: 2em;
      // }
      // &:nth-child(7) {
      //   margin-top: 4em;
      // }
      // &:nth-child(8) {
      //   margin-top: 6em;
      // }
      // &:nth-child(10) {
      //   margin-top: 2em;
      // }
      // &:nth-child(11) {
      //   margin-top: 4em;
      // }
    }
    .ring_wrapper {
      width: 9.5em;
      height: 9.5em;
      border-radius: 50%;
      border: 2px solid $color-pink;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
    }
    .why_logo {
      width: 100%;
      max-width: 4.375em;
      border-radius: 50%;
    }
    h4 {
      @include textStyles(2.125em, normal, normal);
      @include poppinsLight;
      margin-top: 0.5em;
      text-transform: capitalize;
    }
  }
}
.homesec4 {
  padding: 6.625em 4.25em;
  background-color: $color-primary;
  .applications_flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    // opacity: 0;
    transition: all 0.2s ease-in;
    .applications_left {
      width: 100%;
      max-width: 60%;
      position: relative;
      // padding-left: 9.25em;
      .application_box {
        position: relative;
        padding-left: 9.25em;
        .hollow_text {
          width: 100%;
          max-width: 0%;
          display: inline-block;
          position: absolute;
          left: 0;
          top: 0.6em;
        }
        .app_thumb_image {
          width: 100%;
          max-width: 13.188em;
          height: auto;
          position: absolute;
          bottom: 5.5em;
          left: 0;
          box-shadow: 0px 3px 6px #00000029;
          transition: all 0.3s ease-in-out;
        }
        .app_main_image {
          width: 100%;
          max-width: 59.563em;
          height: auto;
          box-shadow: 0px 3px 6px #00000029;
          transition: all 0.3s ease-in-out;
        }
      }
    }
    .applications_right {
      width: 100%;
      max-width: 40%;
      padding-left: 3em;
      padding-top: 3em;
      .text_container {
        h3 {
          @include headingStyleF50L45;
          @include poppinsSemiBold;
          color: $color-white;
          position: relative;
          width: fit-content;
          &::after {
            content: "";
            position: absolute;
            width: 2.54em;
            height: 3px;
            background-color: $color-pink;
            top: 50%;
            transform: translateY(-50%);
            right: -3em;
          }
        }
        .desc {
          @include descStyleF20L32;
          @include poppinsRegular;
          color: $color-white;
          margin-top: 1.5em;
          max-width: 77%;
          width: 100%;
        }
      }
      ul {
        margin-top: 4em;
      }
      li {
        @include descStyleF20L32;
        @include poppinsRegular;
        letter-spacing: 0.8px;
        color: $color-white;
        text-transform: uppercase;
        color: #f8f8f85c;
        transition: 0.3s color;
        cursor: pointer;
        width: fit-content;
        // font-size: 1.25em;
        font-size: 1.375em;
        &:nth-child(n + 2) {
          // margin-top: 1.2em;
          margin-top: 1.9em;
        }
        // &:hover {
        //   color: $color-white;
        // }
      }
      .application.active {
        color: $color-white;
      }
      .mcm_cta {
        margin-top: 2em;
      }
    }
    .applications_left.active {
      opacity: 0;
    }
  }
}
.homesec5 {
  padding-top: 4em;
  padding-bottom: 3.75em;
  padding-left: 3.75em;
  padding-right: 0;
  .text_container {
    padding-left: 9.75em;
    // opacity: 0;
    transition: all 0.2s ease-in;
    h3 {
      @include headingStyleF50L45;
      @include poppinsBold;
      color: $color-primary;
      position: relative;
      width: fit-content;
      &::after {
        content: "";
        position: absolute;
        width: 2.54em;
        height: 3px;
        background-color: $color-pink;
        top: 50%;
        transform: translateY(-50%);
        right: -3em;
      }
    }
    .desc {
      @include descStyleF20L32;
      @include poppinsMedium;
      color: $color-primary;
      margin-top: 1.5em;
      max-width: 65%;
      width: 100%;
    }
  }
  .install_container {
    padding-left: 17.375em;
    padding-top: 6em;
    position: relative;
    // opacity: 0;
    transition: all 0.2s ease-in;
    .nav_arrow {
      width: 100%;
      max-width: 3.938em;
      position: absolute;
      z-index: 2;
      bottom: 1.875em;
    }
    .nav_arrow_left {
      left: 0;
    }
    .nav_arrow_right {
      left: 6.438em;
    }
    .swiper-slide {
      position: relative;
    }
    .install_img_wrapper {
      width: 100%;
      max-width: 30.375em;
      position: relative;
      // &::after {
      //   content: "";
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      //   width: 100%;
      //   height: 100%;
      //   background-image: linear-gradient(180deg, #1e1e1e00 0%, #1d1d1d 250%);
      //   // rgba(0, 0, 0, 0.4) 10%,
      //   // rgba(255, 0, 0, 0) 90%
      // }
      img {
        width: 100%;
        max-width: 30.375em;
        height: auto;
      }
    }
    .install_count {
      @include textStyles(6.125em, normal, normal);
      @include poppinsBold;
      position: absolute;
      left: 0.357em;
      bottom: 0.357em;
      color: #ffffff70;
    }
    .install_title {
      @include mbF16L26;
      @include poppinsBold;
      color: $color-primary;
      position: absolute;
      width: 100%;
      max-width: 42%;
      min-height: 5.188em;
      bottom: 0;
      left: 2.9em;
    }
  }
}
.homesec6 {
  padding: 7.5em 7.375em 0;
  background-color: $color-primary;
  .text_container {
    h3 {
      @include headingStyleF50L45;
      @include poppinsSemiBold;
      position: relative;
      width: fit-content;
      margin: 0 auto;
      color: $color-white;
      &::after {
        content: "";
        position: absolute;
        width: 4.233em;
        height: 2.5px;
        background-color: $color-pink;
        top: 50%;
        transform: translateY(-50%);
        right: -5em;
      }
      &::before {
        content: "";
        position: absolute;
        width: 4.233em;
        height: 2.5px;
        background-color: $color-pink;
        top: 50%;
        transform: translateY(-50%);
        left: -5em;
      }
    }
    .desc {
      @include descStyleF20L32;
      @include poppinsRegular;
      margin: 0 auto;
      width: 100%;
      max-width: 80%;
      margin-top: 2em;
      color: #f8f8f8;
      text-align: center;
    }
  }
  .success_swiper_cont {
    padding-top: 3em;
    padding-bottom: 7em;
    position: relative;
    .blur_img {
      width: 100%;
      max-width: 10.813em;
      position: absolute;
      z-index: 2;
    }
    .blur_left {
      top: 0;
      left: -0.5em;
    }
    .blur_right {
      top: 0;
      right: -0.5em;
    }
    .success_logo_img {
      width: 100%;
      max-width: 14.188em;
      height: auto;
    }
  }
}
.homesec7 {
  background-color: $color-primary;
  padding-left: 10em;
  padding-right: 10em;
  padding-bottom: 7.5em;
  .testimonial_flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .testimonial_left {
      width: 100%;
      max-width: 50%;
      padding-top: 7em;
      .text_container {
        position: relative;
        padding-left: 2em;
        .quote_logo {
          width: 100%;
          max-width: 4.5em;
          position: absolute;
          left: 0;
          top: -2.7em;
        }
        h3 {
          @include headingStyleF50L45;
          @include poppinsSemiBold;
          color: $color-white;
          position: relative;
          width: fit-content;
          &::after {
            content: "";
            position: absolute;
            width: 2.54em;
            height: 3px;
            background-color: $color-pink;
            top: 50%;
            transform: translateY(-50%);
            right: -3em;
          }
        }
      }
      .testimonial_swiper_cont {
        margin-top: 3em;
        margin-right: 8.625em;
        .swiper-slide {
          background-color: #353535;
          box-shadow: 2px 6px 12px #00000029;
        }
        .testimonial_text_wrapper {
          padding: 2.188em 2.5em;
        }
        .testimonial_title {
          @include textStyles(1.75em, normal, normal);
          @include poppinsBold;
          color: #ba5b4a;
          text-transform: capitalize;
        }
        .desc {
          @include descStyleF20L32;
          @include poppinsRegular;
          color: $color-white;
          margin-top: 0.5em;
        }
        .prev_next_wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 1.5em;
          @include descStyleF20L32;
          @include poppinsLight;
          color: $color-white;
          text-transform: capitalize;
        }
        .prev_text {
          cursor: pointer;
          padding-left: 5em;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            width: 4.233em;
            height: 1px;
            background-color: $color-pink;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
          }
        }
        .next_text {
          cursor: pointer;
          padding-right: 5em;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            width: 4.233em;
            height: 1px;
            background-color: $color-pink;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
          }
        }
        .prev_text.swiper-button-disabled,
        .next_text.swiper-button-disabled {
          opacity: 0.5;
          pointer-events: none;
        }
      }
    }
    .testimonial_right {
      width: 100%;
      max-width: 50%;

      .testi_img_wrapper {
        position: relative;
        width: 100%;
        max-width: 50.75em;
        margin-left: auto;
      }
      .testiimg {
        width: 100%;
        max-width: 50.75em;
        height: auto;
      }
      .testiplayicon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        max-width: 9.563em;
        height: auto;
        cursor: pointer;
      }
    }
  }
}
@media (min-width: 2560px) {
  .homesec1 {
    .text_conatiner {
      h1 {
        max-width: 88%;
      }
    }
  }
  .homesec2 {
    .aboutsec {
      .left_para {
        max-width: 100%;
      }
    }
  }
}
@media (max-width: 1600px) {
  .homesec4 {
    .applications_flex {
      .applications_right {
        li{
          &:nth-child(n+2) {
            margin-top: 1.5em;
          }
        }
      }
    }
  }
}
@media (max-width: 1536px) {
  .homesec4 {
    .applications_flex {
      .applications_right {
        li{
          &:nth-child(n+2) {
            margin-top: 2.4em;
          }
        }
      }
    }
  }
  .homesec5 {
    .install_container {
      .install_img_wrapper {
        max-width: 35.375em;
        img {
          max-width: 35.375em;
        }
      }
      .install_title {
        max-width: 35%;
      }
    }
  }
}
@media (max-width: 1440px) {
  .homesec2 {
    .aboutsec {
      .left_para {
        max-width: 80%;
      }
    }
  }
}
@media (max-width: 1194px) {
  .homesec1 {
    .text_conatiner {
      width: initial;
      left: 1.5em;
      right: 1.5em;
    }
  }
  .homesec2 {
    .aboutsec {
      padding: 0 1.5em;
    }
  }
  .homesec3 {
    padding-left: 1.5em;
    padding-right: 1.5em;
    .why_flex {
      padding: 0 1.5em;
    }
  }
  .homesec4 {
    padding-left: 1.5em;
    padding-right: 1.5em;
      .applications_flex {
        .applications_right {
          li{
            &:nth-child(n+2) {
              margin-top: 2em;
            }
          }
        }
      }
  }
  .homesec7 {
    padding-left: 1.5em;
    padding-right: 1.5em;
    .testimonial_flex {
      .testimonial_left {
        padding-top: 0;
      }
    }
  }
}
@media (max-width: 1110px) {
  .homesec4 {
    .applications_flex {
      .applications_right {
        li{
          &:nth-child(n+2) {
            margin-top: 1em;
          }
        }
      }
    }
  }
}

@media (max-width: 1023px) {
  .homesec4 {
    padding-top: 10em;
    padding-bottom: 8em;
    .applications_flex {
      flex-direction: column-reverse;
      .applications_left {
        max-width: 100%;
        padding-left: 0;
        // margin-top: 5.4em;
        .mb_app_swiper_wrapper {
          position: relative;
        }
        // .mb_app_swiper_container {
        //   position: relative;
        //   padding-top: 6em;
        // }
        // .mb_app_slide_wrapper {
        //   padding: 0 3.5em;
        //   position: relative;
        // }
        .mb_app_swiper_container {
          padding-top: 7em;
        }
        // .swiper-slide {
        //   position: relative;
        // }
        .mb_app_slide_wrapper {
          padding-right: 3em;
          position: relative;
          padding-left: 3em;
        }
        .mb_hollow_text {
          color: #f8f8f8c2;
          text-transform: capitalize;
          @include textStyles(3.2em, 1.313em, normal);
          @include cocogooseRegular;
          width: 25%;
          right: 1em;
          top: -1.1em;
          position: absolute;
        }

        .mb_app_main_image {
          width: 100%;
          height: auto;
          box-shadow: 0px 3px 6px #00000029;
        }
        .mb_app_thumb_image {
          width: 100%;
          max-width: 7.1em;
          height: auto;
          position: absolute;
          left: 0;
          bottom: 4em;
          box-shadow: 0px 3px 6px #00000029;
        }
        .nav_arrow {
          width: 100%;
          max-width: 2em;
          position: absolute;
          top: 43%;
          transform: translateY(-50%);
          z-index: 2;
        }
        .nav_arrow_left {
          left: 0;
        }
        .nav_arrow_right {
          right: 0;
        }
        .mcm_cta {
          justify-content: center;
          margin: 0 auto;
          margin-top: 2em;
        }
      }
      .applications_right {
        max-width: 100%;
        padding-top: 0;
        padding-left: 0;
        .text_container {
          h3 {
            @include mbF16L45;
            &::after {
              width: 2.25em;
              right: -3em;
            }
          }
          .desc {
            @include mbF16L26;
            margin-top: 0.625em;
            max-width: 100%;
          }
        }
      }
    }
  }
}
@media (max-width: 835px) {
  .homesec1 {
    .text_conatiner {
      h1 {
        max-width: 100%;
      }
    }
  }
  .homesec2 {
    .productsec {
      .mcm_cta {
        margin: 0 auto;
        padding-top: 4em;
      }
      .text_container {
        bottom: 17.313em;
      }
    }
    .aboutsec {
      .about_flex {
        flex-direction: column;
      }
      .about_flex_left,
      .about_flex_right {
        max-width: 100%;
      }
      .para_right {
        padding-top: 3em;
      }
    }
    .productsec {
      padding-left: 1.5em;
      padding-right: 1.5em;
      .mcm_cta {
        margin: 0 auto;
      }
      .hollow_text {
        right: 0;
      }
      .text_container {
        // opacity: 0;
        p {
          max-width: 90%;
        }
      }
      // .mb_product_swiper_cont {
      // opacity: 0;
      // }
      .products_name_flex {
        // bottom: 0.5em;
        bottom: 5.5em;
      }
    }
  }
  .homesec5 {
    padding-top: 3em;
    padding-bottom: 4.5em;
    padding-left: 1.5em;
    .text_container {
      padding-left: 0;
      h3 {
        @include mbF16L45;
        @include poppinsSemiBold;
      }
      .desc {
        max-width: 96%;
      }
    }
  }
  .homesec6 {
    padding-left: 1.5em;
    padding-right: 1.5em;
    padding-top: 8em;
    .text_container {
      h3 {
        @include mbF16L45;
        @include poppinsSemiBold;
        &::before {
          left: -3em;
        }
        &::after {
          right: -3em;
        }
        &::after,
        &::before {
          width: 2.25em;
        }
      }
      .desc {
        max-width: 100%;
        margin-top: 0;
        @include mbF16L26;
        @include poppinsRegular;
      }
    }
    .success_swiper_cont {
      .blur_img {
        max-width: 6.813em;
      }
    }
  }
  .homesec7 {
    .testimonial_flex {
      .testimonial_left {
        .text_container {
          h3 {
            font-size: 2.125em;
          }
        }
        .testimonial_swiper_cont {
          margin-right: 2.5em;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .homesec1 {
    overflow-x: hidden;
    height: 92.5%;
    .text_conatiner {
      .products_name_flex {
        max-width: 24.2em;
        span {
          @include textStyles(1.6em, 0.8em, normal);
        }
      }
      h1 {
        @include textStyles(3.5em, 1.3em, normal);
        max-width: 100%;
        margin-bottom: 0;
      }
      .watch_video_flex {
        max-width: 15em;
      }
      .watch_text {
        @include textStyles(1.4em, 3.214em, normal);
        color: $color-white;
      }
      .watch_icon_wrapper {
        width: 2.1em;
        height: 2.1em;
      }
      .watch_triangle {
        max-width: 0.9em;
      }
    }
    .scroll_down {
      left: 49%;
    }
  }
  .homesec2 {
    padding-top: 9.5em;
    padding-bottom: 0;
    .aboutsec {
      h3 {
        @include mbF16L45;
        &::after {
          width: 2.25em;
          right: -3em;
        }
      }
      .left_para {
        @include textStyles(2.4em, 1.458em, normal);
        margin-top: 0.833em;
        max-width: 100%;
      }
      .para_right {
        @include mbF16L26;
        padding-top: 2.5em;
      }
    }
    .productsec {
      padding-top: 10em;
      padding-bottom: 6em;
      .text_container {
        position: initial;
        bottom: initial;
        left: initial;
        h3 {
          @include mbF16L45;
        }
        p {
          max-width: 100%;
          @include mbF16L26;
          margin-top: 1.25em;
        }
      }
      .mb_product_swiper_cont {
        margin-top: 5.3em;
        padding: 0 1em;
        position: relative;
        .swiper-slide {
          width: 100%;
          text-align: center;
        }
        .mb_product_swiper {
          padding-top: 2.5em;
        }
        .mb_productimg {
          width: 85%;
          min-height: 32.5em;
          object-fit: cover;
        }
        .nav_arrow {
          width: 100%;
          max-width: 2em;
          position: absolute;
          top: 52.5%;
          transform: translateY(-52.5%);
          z-index: 1;
        }
        .nav_arrow_left {
          left: 0;
        }
        .nav_arrow_right {
          right: 0;
        }
      }
      .mcm_cta {
        justify-content: center;
        padding-top: 0.5em;
      }
    }
  }
  .homesec3 {
    padding-top: 3em;
    .text_container {
      h3 {
        @include mbF16L45;
        @include poppinsSemiBold;
        &::before {
          left: -3em;
        }
        &::after {
          right: -3em;
        }
        &::after,
        &::before {
          width: 2.25em;
        }
      }
      .desc {
        max-width: 100%;
        margin-top: 0;
        @include mbF16L26;
        @include poppinsRegular;
      }
    }
    .why_flex {
      .flex_circle {
        max-width: 100%;
        &:nth-child(3),
        &:nth-child(4) {
          margin-top: 2em;
        }
        // &:nth-child(2),
        // &:nth-child(4),
        // &:nth-child(6),
        // &:nth-child(8),
        // &:nth-child(10) {
        //   margin-top: 2em;
        // }

        // &:nth-child(3),
        // &:nth-child(5),
        // &:nth-child(7),
        // &:nth-child(9),
        // &:nth-child(11) {
        //   margin-top: 0;
        // }
      }
      h4 {
        @include textStyles(2em, normal, normal);
      }
    }
  }
  .homesec4 {
    .applications_flex {
      .applications_left {
        .mb_hollow_text {
          // width: 41%;
          width: 100%;
          text-align: right;
          max-width: 66%;
        }
        .nav_arrow {
          top: 46%;
          transform: translateY(-46%);
        }
      }
    }
  }
  .homesec5 {
    .text_container {
      h3 {
        @include mbF16L45;
        @include poppinsSemiBold;
      }
      .desc {
        @include mbF16L26;
        margin-top: 0;
      }
    }
    .install_container {
      padding-left: 8.5em;
      padding-top: 2em;
      .nav_arrow {
        max-width: 2.5em;
        bottom: 0;
      }
      .nav_arrow_right {
        left: 3.5em;
      }
      .install_count {
        @include textStyles(3.9em, 1.615em, normal);
      }
      .install_title {
        @include textStyles(1.6em, 1.571em, normal);
        bottom: -1.5em;
        left: 2.5em;
      }
    }
  }
  .homesec6 {
    .success_swiper_cont {
      padding-bottom: 8.5em;
    }
  }
  .homesec7 {
    padding-bottom: 6.1em;
    .testimonial_flex {
      flex-direction: column;
      .testimonial_left {
        max-width: 100%;
        .text_container {
          padding-left: 0;
          h3 {
            @include mbF16L45;
            &::after {
              width: 2.25em;
              right: -3em;
            }
          }
          .quote_logo {
            left: -0.7em;
            top: 0.2em;
            max-width: 2.3em;
          }
        }
        .testimonial_swiper_cont {
          margin-top: 2em;
          margin-right: 0;
          .testimonial_text_wrapper {
            padding: 3em 2.2em;
          }
          .testimonial_title {
            @include textStyles(1.8em, normal, normal);
          }
          .desc {
            @include mbF16L26;
          }
          .prev_next_wrapper {
            margin-top: 0.25em;
            margin-bottom: 4.1em;
            @include textStyles(1.2em, normal, normal);
          }
          .prev_text {
            padding-left: 4em;
            &::before {
              width: 3.23em;
            }
          }
          .next_text {
            padding-right: 4em;
            &::after {
              width: 3.23em;
            }
          }
        }
      }
      .testimonial_right {
        max-width: 100%;
        .testiplayicon {
          max-width: 6em;
        }
      }
    }
  }
}

// animations
// @keyframes productImgAnim {
// 0% {
//   transform: rotateY(180deg);
// }
// 100% {
//   transform: rotateY(0deg);
// }
// 0%{
//   opacity: 0;
// }
// 100%{
//   opacity: 1;
// }
// }
// @keyframes scale {
//   100% {
//     transform: scale(1);
//   }
// }

// @keyframes fade-in {
//   100% {
//     opacity: 1;
//     filter: blur(0);
//   }
// }
