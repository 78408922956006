.react_video_modal_overlay {
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(29, 29, 29, 0.9);
  .react_modal_content {
    position: absolute;
    top: 10em;
    bottom: 10em;
    left: 12.25em;
    right: 12.25em;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    border-radius: 4px;
    outline: none;
    border: none;
    // animation-name: reactModalContentAni;
    // animation-duration: 1s;
    // animation-timing-function: linear;
    // animation-fill-mode: forwards;
  }
  //   @keyframes reactModalContentAni {
  //     0% {
  //       opacity: 0;
  //     }
  //     100% {
  //       opacity: 1;
  //       //   transform: translateY(0);
  //     }
  //   }
  .project_detail_gallery_video {
    width: 100%;
    height: 100%;
  }
  .modal_close_icon {
    position: absolute;
    top: -3em;
    right: 0;
    z-index: 6;
    width: 2em;
    height: 2em;
    cursor: pointer;
  }
}
@media (max-width: 834px) {
  .react_video_modal_overlay {
    .react_modal_content {
      left: 3em;
      right: 3em;
    }
  }
}
// @media (max-width: 767px) {
//   .react_video_modal_overlay {
//     .react_modal_content {
//       top: 25em;
//       bottom: 25em;
//     }
//   }
// }
