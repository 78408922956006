@import "../../common.scss";

.aboutsec2 {
  padding-top: 5.625em;
  padding-bottom: 7.5em;
  background-color: $color-primary;
  .heading_container {
    height: 8.5em;
    margin-bottom: 7.867em;
    overflow: hidden;
    h2 {
      @include textStyles(2.813em, 1.667em, normal);
      @include cocogooseRegular;
      color: #fff;
      width: 100%;
      max-width: 67%;
      margin: 0 auto;
      text-align: center;
      // transform: translateY(130px);
    }
  }
  .about_flex {
    padding-left: 13.125em;
    padding-right: 6em;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .about_left {
      width: 100%;
      max-width: 50%;
      .title_container {
        height: 3em;
        overflow: hidden;
        h3 {
          @include headingStyleF50L45;
          @include poppinsSemiBold;
          color: $color-white;
          position: relative;
          width: fit-content;
          // transform: translateY(50px);
          &::after {
            content: "";
            position: absolute;
            width: 2.54em;
            height: 3px;
            background-color: $color-pink;
            top: 50%;
            transform: translateY(-50%);
            right: -3em;
          }
        }
      }

      p {
        @include descStyleF20L32;
        @include poppinsRegular;
        color: $color-white;
        margin-top: 2.15em;
        margin-bottom: 3.05em;
        width: 100%;
        max-width: 90%;
        // opacity: 0;
        transition: all 0.2s ease-in;
      }
      .mcm_cta {
        // opacity: 0;
        transition: all 0.2s ease-in;
      }
    }
    .about_right {
      width: 100%;
      max-width: 50%;
      padding-top: 7em;
      text-align: right;
      // opacity: 0;
      transition: all 0.2s ease-in;
      .about_right_img {
        width: 100%;
        max-width: 47.188em;
      }
    }
  }
}

.aboutsec3 {
  padding: 8.75em;
  padding-top: 0;
  position: relative;
  background-color: $color-primary;
  .hollow_text {
    position: absolute;
    color: #f8f8f8c2;
    z-index: 3;
    left: 1.7em;
    top: -0.65em;
    // opacity: 0;
    transition: all 0.2s ease-in;
  }
  .ethos_content_wrapper {
    padding-left: 4.125em;
    padding-top: 4.438em;
    padding-bottom: 4.438em;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #1d1d1d6e;
      background-image: url("../../images/about/Ethos_banner.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top;
    }
  }
  .ethos_text_wrapper {
    width: 100%;
    max-width: 34.625em;
    // opacity: 0;
    transition: all 0.2s ease-in;
  }
  .ethos_text {
    @include descStyleF20L32;
    @include poppinsMedium;
    color: $color-primary;
    position: relative;
    z-index: 2;
    overflow: hidden;
    padding: 2.3em;
    // &::after {
    //   content: "";
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 100%;
    //   background-image: linear-gradient(
    //       rgba(226, 226, 226, 0.4),
    //       rgba(226, 226, 226, 0.4)
    //     ),
    //     url("../../images/about/ethosimg.png");
    //   background-size: cover;
    //   background-repeat: no-repeat;
    //   filter: blur(8px);
    //   z-index: -1;
    //   box-shadow: inest 102px 4px 4px #000;
    // }
  }
  .blur_background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("../../images/about/ethosblur.png");
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
    &::after {
      z-index: 1;
      $negativeMargin: -20px;
      content: "";
      z-index: -1;
      position: absolute;
      top: $negativeMargin;
      right: $negativeMargin;
      left: $negativeMargin;
      bottom: $negativeMargin;
      background: inherit;
      filter: blur(8px);
      // box-shadow: inset 0 0 0 31.25em rgba(255, 255, 255, 0.5);
      // box-shadow: inset 0 0 0 31.25em rgba(29, 29, 29, 0.43);
      box-shadow: inset 0 0 0 31.25em rgba(255, 255, 255, 0.36);
    }
  }
}

// .aboutsec3 {
//   padding: 8.75em;
//   padding-top: 0;
//   background-color: $color-primary;
//   .ethos_content_wrapper {
//     position: relative;
//     padding-left: 4.125em;
//     padding-top: 4.438em;
//     padding-bottom: 4.438em;
//     background-image: url("../../images/about/ethosimg.png");
//     background-size: cover;
//   }
//   .ethos_text_wrapper {
//     position: absolute;
//     top: 0;
//   }
// }
.aboutsec4 {
  background-color: $color-primary;
  padding-bottom: 3em;
  .text_container {
    text-align: center;
    h3 {
      margin: 0 auto;
      @include headingStyleF50L45;
      @include poppinsSemiBold;
      color: $color-white;
      position: relative;
      width: fit-content;
      &::after {
        content: "";
        position: absolute;
        width: 2.54em;
        height: 3px;
        background-color: $color-pink;
        top: 50%;
        transform: translateY(-50%);
        right: -3em;
      }
      &::before {
        content: "";
        position: absolute;
        width: 2.54em;
        height: 3px;
        background-color: $color-pink;
        top: 50%;
        transform: translateY(-50%);
        left: -3em;
      }
    }
    p {
      @include descStyleF20L32;
      @include poppinsRegular;
      color: $color-white;
      width: 100%;
      max-width: 70%;
      margin: 0 auto;
      margin-top: 1.65em;
      margin-bottom: 3.75em;
    }
  }
  .mile_swiper_wrapper {
    padding: 0 11.313em;

    .mile_swiper_container {
      padding-bottom: 14em;
      position: relative;
    }

    .mile_year {
      @include textStyles(2.5em, 1.65em, normal);
      @include poppinsMedium;
      color: #f8f8f84d;
      display: block;
      text-align: center;
      // transition: all 0.3s;
    }
    .mile_circle {
      display: block;
      width: 1.375em;
      height: 1.375em;
      background-color: #f8f8f84d;
      margin: 0 auto;
      margin-top: 3.25em;
      border-radius: 50%;
      transform: translateY(0.6em);
      // transition: all 0.3s;
    }
    .mile_desc {
      @include descStyleF20L32;
      @include poppinsMedium;
      color: $color-white;
      position: absolute;
      width: 59em;
      left: -20em;
      text-align: center;
      bottom: -6em;
      // transition: all 0.3s;
      // opacity: 0;
    }
    .swiper-slide-active {
      .mile_year {
        @include textStyles(3.75em, 1.1em, normal);
        color: $color-pink;
      }
      .mile_circle {
        width: 2em;
        height: 2em;
        background-color: $color-pink;
        transform: translateY(0.2em);
      }
      .mile_desc {
        opacity: 1;
      }
    }
    .mile_line {
      width: 100%;
      max-width: 98%;
      position: absolute;
      left: 1em;
      bottom: 14.6em;
    }
    .prev_wrapper {
      position: absolute;
      left: 0;
      // bottom: -0.27em;
    }
    .next_wrapper {
      position: absolute;
      right: 0;
    }
    .prev_next_wrapper {
      cursor: pointer;
      z-index: 1;

      bottom: 9.73em;
      img {
        width: 100%;
        max-width: 3.938em;
      }
      p {
        @include textStyles(1.25em, 2.25em, normal);
        @include poppinsLight;
        text-align: center;
        color: $color-white;
        text-transform: capitalize;
      }
    }
  }
}

.aboutsec5 {
  background-color: $color-primary;
  padding-left: 18.25em;
  padding-right: 18.25em;
  padding-bottom: 9.375em;
  .team_flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .team_left {
    width: 100%;
    max-width: 55%;
  }
  .team_right {
    width: 100%;
    max-width: 45%;
    align-self: center;
  }
  .member_flex {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .member_left {
    .member_wrapper {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $color-primary;
        opacity: 0;
        transition: opacity 0.3s;
      }
    }
    .member_img {
      height: auto;
      width: 100%;
      max-width: 20.813em;
    }
    .member_info {
      position: absolute;
      left: 1.625em;
      bottom: 1.2em;
      span {
        display: block;
        color: $color-white;
        text-transform: capitalize;
      }
    }
    .member_name {
      @include textStyles(1.25em, 2.25em, normal);
      @include poppinsSemiBold;
      transition: opacity 0.3s;
      // opacity: 0;
    }
    .member_profile {
      @include textStyles(1em, normal, 0.28px);
      transition: opacity 0.3s;
      // opacity: 0;
    }
    .member_wrapper {
      &:hover::before {
        opacity: 0.3;
      }
      &:hover .member_name {
        opacity: 1;
      }
      &:hover .member_profile {
        opacity: 1;
      }
    }
  }
  .member_right {
    margin-left: 2.5em;
    .member_wrapper:last-child {
      margin-top: 2.5em;
    }
    .member_wrapper {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $color-primary;
        opacity: 0;
        transition: opacity 0.3s;
      }
    }
    .member_img {
      height: auto;
      width: 100%;
      max-width: 20.813em;
    }
    .member_info {
      position: absolute;
      left: 1.625em;
      bottom: 1.2em;
      span {
        display: block;
        color: $color-white;
        text-transform: capitalize;
      }
    }
    .member_name {
      @include textStyles(1.25em, 2.25em, normal);
      @include poppinsSemiBold;
      transition: opacity 0.3s;
      // opacity: 0;
    }
    .member_profile {
      @include textStyles(1em, normal, 0.28px);
      transition: opacity 0.3s;
      // opacity: 0;
    }
    .member_wrapper {
      &:hover::before {
        opacity: 0.3;
      }
      &:hover .member_name {
        opacity: 1;
      }
      &:hover .member_profile {
        opacity: 1;
      }
    }
  }

  .text_container {
    h3 {
      @include headingStyleF50L45;
      @include poppinsSemiBold;
      color: $color-white;
      position: relative;
      width: fit-content;
      &::after {
        content: "";
        position: absolute;
        width: 2.54em;
        height: 3px;
        background-color: $color-pink;
        top: 50%;
        transform: translateY(-50%);
        right: -3em;
      }
    }
    p {
      @include descStyleF20L32;
      @include poppinsRegular;
      color: $color-white;
      width: 100%;
      margin-top: 2em;
    }
  }
}

.aboutsec6 {
  padding-left: 4.125em;
  padding-right: 4.125em;
  padding-bottom: 9.938em;
  background-color: $color-primary;
  .text_container {
    text-align: center;
    h3 {
      @include headingStyleF50L45;
      @include poppinsSemiBold;
      color: $color-white;
      position: relative;
      width: fit-content;
      margin: 0 auto;
      &::after {
        content: "";
        position: absolute;
        width: 2.54em;
        height: 3px;
        background-color: $color-pink;
        top: 50%;
        transform: translateY(-50%);
        right: -3em;
      }
      &::before {
        content: "";
        position: absolute;
        width: 2.54em;
        height: 3px;
        background-color: $color-pink;
        top: 50%;
        transform: translateY(-50%);
        left: -3em;
      }
    }
    p {
      @include descStyleF20L32;
      @include poppinsRegular;
      color: $color-white;
      margin: 0 auto;
      margin-top: 2.15em;
      margin-bottom: 3.05em;
      width: 100%;
      max-width: 70%;
    }
  }
  .awards_swiper_wrapper {
    position: relative;
    .blur_left {
      width: 100%;
      max-width: 21.563em;
      position: absolute;
      top: -1em;
      left: -1em;
      z-index: 2;
    }
    .blur_right {
      width: 100%;
      max-width: 21.563em;
      position: absolute;
      top: -1em;
      right: -1em;
      z-index: 2;
    }
    .swiper-slide {
      text-align: center;
    }
    .awards_img {
      height: auto;
      width: 100%;
      max-width: 16.313em;
    }
    .awards_name {
      display: block;
      @include descStyleF20L32;
      @include poppinsSemiBold;
      text-align: center;
      margin-top: 1.2em;
      color: $color-white;
    }
    .awards_year {
      color: $color-white;
      @include textStyles(0.875em, normal, 0.28px);
      margin-top: 0.5em;
      display: block;
    }
  }
}

@media (max-width: 1194px) {
  .aboutsec2 {
    padding-left: 1.5em;
    padding-right: 1.5em;
    h2 {
      max-width: 100%;
    }
    .about_flex {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .aboutsec3 {
    padding-left: $pad-left-right;
    padding-right: $pad-left-right;
    .hollow_text {
      left: 0.5em;
    }
  }
  .aboutsec4 {
    .mile_swiper_wrapper {
      padding: 0 1.5em;
      .mile_line {
        max-width: 97%;
      }
      .mile_desc {
        left: -23em;
        bottom: -7em;
      }
    }
  }
  .aboutsec5 {
    padding-left: 1.5em;
    padding-right: 1.5em;
    .team_right {
      padding-left: 2em;
    }
  }
  .aboutsec6 {
    padding-left: 1.5em;
    padding-right: 1.5em;
  }
}
@media (max-width: 992px) {
  .aboutsec2 {
    .about_flex {
      .about_right {
        .about_right_img {
          max-width: 100%;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .aboutsec2 {
    padding-top: 0em;
    padding-bottom: 6em;
    h2 {
      @include textStyles(2.4em, 1.417em, normal);
      margin-bottom: 3.333em;
    }
    .about_flex {
      flex-direction: column;
      .about_left {
        max-width: 100%;
        .title_container {
          h3 {
            @include mbF16L45;
          }
        }
        p {
          max-width: 100%;
          @include mbF16L26;
          margin-top: 1.25em;
          margin-bottom: 0;
        }
      }
      .about_right {
        max-width: 100%;
        padding-top: 3em;
        .mcm_cta {
          justify-content: center;
          margin-top: 1.111em;
        }
      }
    }
  }
  .aboutsec3 {
    padding-left: 0;
    padding-right: 0;
    .hollow_text {
      font-size: 5.2em;
      left: 0.2em;
    }
    .ethos_content_wrapper {
      padding: 3.5em;
      padding-top: 15.1em;
      padding-bottom: 5em;
    }
    .ethos_text {
      @include mbF16L26;
      padding: 2em 1.5em;
    }
  }
  .aboutsec4 {
    padding-bottom: 8em;

    .text_container {
      padding: 0 1.5em;
      h3 {
        @include mbF16L45;
      }
      p {
        @include mbF16L26;
        max-width: 100%;
        margin-top: 1em;
        margin-bottom: 1.875em;
      }
    }
    .mile_swiper_wrapper {
      .mile_line {
        max-width: 92%;
      }
      .mile_circle {
        width: 1.2em;
        height: 1.2em;
        transform: translateY(3.2em);
        margin-top: 1em;
      }
      .mile_desc {
        left: -7em;
        width: 22em;
        bottom: -11em;
      }
      .mile_year {
        transform: translateY(1.5em);
      }
      .swiper-slide-active {
        .mile_year {
          @include textStyles(2.5em, normal, normal);
          transform: translateY(0.5em);
        }
        .mile_circle {
          width: 1.2em;
          height: 1.2em;
          transform: translateY(0);
          margin-top: 3.25em;
        }
      }
      .mile_year {
        @include textStyles(1.8em, normal, normal);
      }
      // .next_wrapper {
      // right: -0.5em;
      // }
      .prev_next_wrapper {
        bottom: 10.5em;
        img {
          max-width: 2em;
        }
      }
    }
  }
  .aboutsec5 {
    .team_right {
      padding-left: 0;
      max-width: 100%;
    }
    .team_left {
      max-width: 100%;
    }
    .team_flex {
      flex-direction: column-reverse;
    }
    .text_container {
      h3 {
        @include mbF16L45;
      }
      p {
        @include mbF16L26;
        max-width: 100%;
        margin-top: 0.4em;
        margin-bottom: 1.875em;
      }
    }
    .member_left {
      .member_wrapper::before {
        opacity: 0.3;
      }
      .member_name {
        opacity: 1;
      }
      .member_profile {
        opacity: 1;
      }
    }
    .member_right {
      .member_wrapper::before {
        opacity: 0.3;
      }
      .member_name {
        opacity: 1;
      }
      .member_profile {
        opacity: 1;
      }
    }
  }
  .aboutsec6 {
    .text_container {
      h3 {
        @include mbF16L45;
      }
      p {
        @include mbF16L26;
        max-width: 100%;
        margin-top: 0.4em;
        margin-bottom: 2.5em;
      }
    }
    .awards_swiper_wrapper {
      .blur_right,
      .blur_left {
        max-width: 7em;
        min-height: 30em;
      }
      .awards_img {
        max-width: 18em;
      }
      .awards_name {
        @include mbF16L45;
        margin-top: 0;
      }
      .awards_year {
        font-size: 1.4em;
        margin-top: 0;
      }
    }
  }
}
