@import "../../common.scss";
.blogsec2 {
  padding-top: 5.625em;
  padding-bottom: 9.375em;
  background-color: $color-primary;
  .featured_blog_flex {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-left: 13.063em;
    margin-right: 13.063em;
    box-shadow: 9px 9px 6px #00000042;
  }
  .featured_blog_left {
    width: 100%;
    max-width: 55%;
    padding-top: 3.125em;
    padding-bottom: 1em;
    padding-left: 1.563em;
    padding-right: 2em;
    .featured_date_and_cat {
      @include textStyles(1.125em, normal, 0.36px);
      @include poppinsRegular;
      color: #616161;
    }
    .featured_blog_title {
      @include textStyles(1.875em, 1.333em, 0.6px);
      @include poppinsSemiBold;
      color: #f8f8f8;
      margin-top: 1em;
      margin-bottom: 1.2em;
    }
    .featured_blog_desc {
      @include descStyleF20L32;
      @include poppinsRegular;
      letter-spacing: 0.4px;
      color: #f8f8f8;
      margin-bottom: 1em;
    }
    .mcm_cta {
      width: fit-content;
    }
  }
  .featured_blog_right {
    width: 100%;
    max-width: 45%;
    .featured_blog_img {
      width: 100%;
      height: 30.5em;
      object-fit: cover;
    }
  }
  .blogs_flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-top: 6.25em;
    padding-left: 6.25em;
    padding-right: 6.25em;
  }
  .blog_card_wrapper {
    width: 100%;
    max-width: 28%;
    box-shadow: 9px 9px 6px #00000042;
  }
  .blog_card_wrapper:nth-child(n + 4) {
    margin-top: 6.25em;
  }
  // .blog_img {
  //   width: 100%;
  //   object-fit: cover;
  // }
  // .blog_details_wrapper {
  //   padding: 1.438em;
  // }
  // .blog_date_and_cat {
  //   @include textStyles(1em, normal, 0.32px);
  //   @include poppinsRegular;
  //   color: #616161;
  // }
  // .blog_title {
  //   @include textStyles(1.625em, 1.385em, 0.52px);
  //   @include poppinsSemiBold;
  //   color: #f8f8f8;
  //   margin-top: 0.5em;
  //   margin-bottom: 0.5em;
  // }
  // .blog_desc {
  //   @include textStyles(1.125em, 1.556em, 0.36px);
  //   @include poppinsRegular;
  //   color: #f8f8f8;
  //   margin-bottom: 1em;
  // }
  // .mcm_cta {
  //   width: fit-content;
  // }
  .load_more_wrapper {
    text-align: center;
    margin-top: 5em;
  }
}
@media (max-width: 1194px) {
  .blogsec2 {
    .featured_blog_flex {
      margin-left: 1.5em;
      margin-right: 1.5em;
    }
    .featured_blog_right {
      .featured_blog_img {
        height: 34.5em;
      }
    }
    .blogs_flex {
      padding-top: 0;
      padding-left: 1.5em;
      padding-right: 1.5em;
    }
    .blog_card_wrapper {
      max-width: 47%;
    }
    .blog_card_wrapper:nth-child(n + 4) {
      margin-top: 0;
    }
    .blog_card_wrapper:nth-child(n + 3) {
      margin-top: 5em;
    }
  }
}
@media (max-width: 767px) {
  .blogsec2 {
    padding-top: 0;
    .blog_card_wrapper {
      max-width: 100%;
    }
    .blog_card_wrapper:nth-child(n + 3) {
      margin-top: 0;
    }
    .blog_card_wrapper:nth-child(n + 2) {
      margin-top: 4em;
    }
    // .blog_date_and_cat {
    //   font-size: 1.2em;
    //   letter-spacing: 0.2px;
    // }
    // .blog_details_wrapper {
    //   padding: 0.8em;
    // }
    // .blog_title {
    //   @include textStyles(2.2em, 1.455em, 0.44px);
    //   margin-top: 0.5em;
    //   margin-bottom: 0.455em;
    // }
    // .blog_desc {
    //   @include mbF16L26;
    // }
  }
}
