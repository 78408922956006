@import "../../common.scss";

.privacy_sec1 {
  padding: 6.25em 0 0;
  background-color: #1d1d1d;
}

.privacy_sec2 {
  padding: 6.25em 0 6.25em;
  overflow: hidden;
  .main_heading {
    font-size: 3.75em;
    font-family: "poppins";
    font-weight: 600;
    letter-spacing: 0px;
    line-height: normal;
    margin-bottom: 1.3334em;
  }
  .para_wrapper {
    margin-bottom: 3.75em;
    &:last-child {
      margin-bottom: 0;
    }
    .title {
      font-size: 1.75em;
      font-family: "poppins";
      font-weight: 600;
      letter-spacing: 0px;
      line-height: normal;
      margin-bottom: 0.5em;
      margin-top: 2em;
    }
    .description {
      font-size: 1.5em;
      font-family: "poppins";
      font-weight: 400;
      letter-spacing: 0px;
      line-height: normal;
      margin-bottom: 1em;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .link {
      // text-decoration: underline;
      color: inherit;
      &:hover {
        color: inherit;
        text-decoration: underline;
      }
    }
  }
  .list_wrapper {
    font-size: 1.5em;
    font-family: "poppins";
    font-weight: 400;
    letter-spacing: 0px;
    line-height: normal;
    padding-left: 1.25em;
    margin-bottom: 1em;
    li {
      list-style: disc;
    }
  }
  .data_table {
    border: none;
    td,
    th {
      border: none;
      font-size: 1.5em;
      font-family: "poppins";
      font-weight: 400;
      letter-spacing: 0px;
      line-height: normal;
      vertical-align: top;
      text-align: left;
    }
    td {
      width: 30em;
    }
    th {
      width: 18em;
    }
    .text {
      position: relative;
      margin-bottom: 1em;
      overflow-wrap: break-word;
      .colon {
        position: absolute;
        right: 10%;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

@media (max-width: 767px) {
  .privacy_sec1 {
    padding: 6.5em 0 0;
  }
  .privacy_sec2 {
    padding: 6em 0 6em;
    .main_heading {
      font-size: 3.6em;
      letter-spacing: 0px;
      line-height: 1.25;
      margin-bottom: 1.112em;
    }
    .para_wrapper {
      margin-bottom: 4em;
      .title {
        font-size: 1.8em;
        letter-spacing: 0px;
        line-height: 1.5;
        margin-bottom: 0.667em;
        margin-top: 1.667em;
      }
      .description {
        font-size: 1.6em;
        letter-spacing: 0px;
        line-height: 1.8;
        margin-bottom: 1.875em;
      }
    }
    .list_wrapper {
      font-size: 1.6em;
      letter-spacing: 0px;
      line-height: 1.8;
      margin-bottom: 1.875em;
    }
    .data_table {
      width: 100%;
      max-width: 100%;
      td,
      th {
        font-size: 1.6em;
        letter-spacing: 0px;
        line-height: 1.8;
      }
      th {
        width: 100%;
        max-width: 1em;
      }
      td {
        width: 100%;
        max-width: 100%;
        .text {
          margin-right: 0;
        }
      }
      .text {
        margin-bottom: 1em;
        margin-right: 0.5em;
        .colon {
          right: -2%;
          top: 22%;
        }
      }
    }
  }
}
