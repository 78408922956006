@import "../../common.scss";
.footer {
  padding-left: 4.875em;
  padding-right: 4.875em;
  background-color: $color-primary;
  .footer_flex {
    border-top: 2px solid $color-pink;
    padding: 4.375em 3.875em 6.5em;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
  }
  .back_to_img {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 4em;
    cursor: pointer;
  }
  .back_to_span {
    position: absolute;
    bottom: 0.5em;
    left: 54%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 4em;
    white-space: nowrap;
    @include textStyles(1.125em, 1.778em, normal);
    @include poppinsRegular;
    color: $color-white;
    cursor: pointer;
  }
  .social_icons_content,
  .get_in_touch_wrapper,
  ul {
    width: 100%;
    max-width: 32%;
  }

  .social_icons_content {
    h2 {
      @include textStyles(3.125em, 1.5em, normal);
      @include cocogooseRegular;
      color: $color-white;
    }
    .social_icons_wrapper {
      width: 100%;
      max-width: 28.5em;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-top: 3em;
    }
    a {
      width: 2.5em;
      height: 2.5em;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $color-white;
      transition: 0.3s background-color;
      &:hover {
        background-color: $color-pink;
      }
      &:nth-child(4) {
        // transition: none;
        // pointer-events: none;
        // &:hover {
        //   background-color: #fff;
        //   cursor: default;
        // }
      }
      .fb_logo {
        max-width: 0.82em;
      }
      .twitter_img {
        max-width: 1em;
      }
      .pinterest_img {
        max-width: 1em;
      }
      .youtube_logo {
        max-width: 1.5em;
        // pointer-events: none;
        //      &:hover {
        //     background-color: transparent;
        // }
      }
      
      img {
        width: 100%;
        max-width: 1.25em;
      }
    }
  }
  ul {
    // text-align: center;
    // max-width: 15%;
    max-width: 25%;
    .footer_link {
      @include textStyles(1.563em, 1.75em, 2px);
      @include poppinsSemiBold;
      text-transform: uppercase;
      color: $color-white;
    }
    .footer_link_active {
      @include poppinsBold;
      text-decoration: underline;
    }
  }
  .get_in_touch_wrapper {
    max-width: 25%;
    h4 {
      @include textStyles(1.875em, normal, normal);
      @include poppinsSemiBold;
      text-transform: uppercase;
      color: $color-white;
    }
    p span {
      display: inline-block;
      width: 1px;
      height: 12px;
      margin-left: 1em;
      margin-right: 1em;
      background-color: $color-white !important;
    }
    .contact_details_wrapper {
      margin-top: 1em;
      margin-bottom: 2em;
    }
    .contact_desc {
      @include textStyles(1.375em, 2.045em, normal);
      @include poppinsLight;
      color: $color-white;
    }
    p a {
      @include textStyles(1.375em, 2.045em, normal);
      @include poppinsLight;
      color: $color-white;
    }
    .mcm_logo {
      width: 100%;
      max-width: 5.563em;
    }
    .footer_image {
      cursor: default;
    }
    .footer_logos_span {
      height: 3em;
      margin-left: 2em;
      margin-right: 2em;
    }
  }
}
.copyright_text {
  @include textStyles(0.875em, 2.286em, normal);
  @include poppinsSemiBold;
  color: $color-primary;
  text-align: center;
  a {
    color: $color-primary;
  }
}
@media (max-width: 1194px) {
  .footer {
    padding-left: 1.5em;
    padding-right: 1.5em;
    .footer_flex {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
@media (max-width: 834px) {
  .footer {
    .get_in_touch_wrapper,
    ul {
      max-width: 32%;
    }
    .social_icons_content {
      h2 {
        font-size: 2em;
      }
    }
  }
  .copyright_text {
    padding-bottom: 6.5em;
  }
}
@media (max-width: 767px) {
  .footer {
    .footer_flex {
      flex-direction: column;
      padding-top: 3em;
      padding-bottom: 2em;
    }
    .social_icons_content,
    .get_in_touch_wrapper,
    ul {
      max-width: 100%;
    }
    ul {
      margin-top: 3em;
      .footer_link {
        @include textStyles(1.8em, 2em, normal);
      }
    }
    .social_icons_content {
      h2 {
        @include textStyles(2.4em, 1.458em, normal);
      }
    }
    .get_in_touch_wrapper {
      margin-top: 3em;
      p a {
        @include textStyles(1.6em, 2em, normal);
      }
      .mcm_logo {
        max-width: 4.7em;
      }
      .social_icons_wrapper {
        width: 100%;
        max-width: 25.5em;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 3em;
        a {
          width: 2.5em;
          height: 2.5em;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $color-white;
          transition: 0.3s background-color;
          &:hover {
            background-color: $color-pink;
          }
          .social_icon_img {
            width: 100%;
            max-width: 1.25em;
          }
          .fb_logo {
            max-width: 0.82em;
          }
          .twitter_img {
            max-width: 1em
          }
          .pinterest_img {
            max-width: 1em
          }
          // &:nth-child(4) {
          //   pointer-events: none;
          //   .social_icon_img {
          //     max-width: 3em;
          //   }
          // }
        }
      }
    }
  }
  .copyright_text {
    @include textStyles(1.2em, 2.667em, normal);
    @include poppinsRegular;
    padding-bottom: 4.583em;
  }
}
