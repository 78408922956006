@import "../../common.scss";
.contactsec2 {
  padding-top: 15.313em;
  padding-left: 10.563em;
  padding-right: 11.875em;
  padding-bottom: 5em;
  background-color: $color-primary;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 4%;
    height: 85%;
    width: 100%;
    max-width: 73%;
    background-color: $color-white;
    // z-index: -1;
  }
  .contact_details_flex {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    z-index: 2;
  }
  .contact_details_flex_left {
    width: 100%;
    max-width: 38%;
    background-color: $color-primary;
    box-shadow: 9px 9px 6px #00000042;
    padding: 5em 3em;
    // padding-right: 0;
    h3 {
      @include poppinsSemiBold;
      font-size: 2.5em;
      line-height: 1.125em;
      color: $color-white;
      position: relative;
      width: fit-content;
      letter-spacing: 4.4px;
      text-transform: uppercase;
      &::after {
        content: "";
        position: absolute;
        width: 2.54em;
        height: 3px;
        background-color: $color-pink;
        top: 50%;
        transform: translateY(-50%);
        right: -3em;
      }
    }
    .contact_details_wrapper {
      margin-top: 6em;
      .contact_details_list_item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      .contact_details_list_item:nth-child(n + 2) {
        margin-top: 4em;
      }
      .contact_details_logo {
        width: 100%;
        max-width: 4.188em;
      }
      .location_icon {
        width: 100%;
        max-width: 2.697em;
      }
      .call_icon {
        width: 100%;
        max-width: 3.16em;
      }
      .email_icon {
        width: 100%;
        max-width: 3.5em;
      }
      p {
        @include descStyleF20L32;
        @include poppinsRegular;
        color: $color-white;
        margin-left: 2.5em;
      }
      .mcm_text {
        display: block;
        font-size: 1.3em;
        font-weight: 600;
      }
      .add_desc {
        width: 100%;
        max-width: 70%;
      }
      a {
        color: $color-white;
      }
    }
    .social_icons_wrapper {
      margin-top: 9em;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      max-width: 28.5em;
      .social_icons_circle {
        align-items: center;
        background-color: #fff;
        border-radius: 50%;
        display: flex;
        height: 2.5em;
        justify-content: center;
        transition: background-color 0.3s;
        width: 2.5em;
        &:hover {
          background-color: #e27663;
        }
        // &:nth-child(4) {
        //   transition: none;
        //   pointer-events: none;
        //   &:hover {
        //     background-color: #fff;
        //     cursor: default;
        //   }
        // }
      }
      img {
        max-width: 1.25em;
        width: 100%;
      }
      .fb_img {
        max-width: 0.82em;
      }
      .twitter_img {
        max-width: 1em;
      }
      .pinterest_img {
        max-width: 1em;
      }
      // .youtube {
      //   width: 100%;
      //   max-width: 2.7em;
      //   pointer-events: none;
      //   cursor: default;
      //   .youtube_logo {
      //     max-width: 2.6em;
      //     pointer-events: none;

      // }
    }
  }
  .contact_details_flex_right {
    width: 100%;
    max-width: 68%;
    padding-left: 7em;
    padding-top: 1.5em;
    h3 {
      @include poppinsSemiBold;
      font-size: 2.5em;
      line-height: 1.125em;
      color: $color-primary;
      position: relative;
      width: fit-content;
      letter-spacing: 4.4px;
      text-transform: uppercase;
      &::after {
        content: "";
        position: absolute;
        width: 2.54em;
        height: 3px;
        background-color: $color-pink;
        top: 50%;
        transform: translateY(-50%);
        right: -3em;
      }
    }
    p {
      @include descStyleF20L32;
      @include poppinsRegular;
      margin-top: 1em;
      margin-bottom: 3em;
    }
    .input_field_wrapper {
      position: relative;
    }

    .input_field_wrapper:nth-child(n + 2) {
      margin-top: 4.563em;
    }
    .country_code_field {
      display: flex;
      align-items: flex-end;
      .country_code {
        position: relative;
        width: 100%;
        max-width: 10%;
        margin-right: 5%;
        border-bottom: 2px solid #1d1d1d6d;
        .countrycode_select {
          background-color: transparent;
          font-size: 1.5em;
          letter-spacing: 1.32px;
          padding-bottom: 0.2em;
          @include poppinsRegular;
          padding-left: 1em;
          padding-right: 2em;
          border: none;
          cursor: pointer;
          appearance: none;
          -webkit-appearance: none;
        }
        select {
          -webkit-appearance: none;
          appearance: none;
          color: #000 !important;
          option {
            // background-color: $color-A7A7A7 !important;
            color: #000 !important;
          }
        }
        .dropwdown_icon {
          position: absolute;
          top: 2.1em;
          right: 10%;
          max-width: 1em;
        }
      }
      .number_field {
        position: relative;
        width: 100%;
        max-width: 85%;
      }
    }
    .input_field {
      width: 100%;
      border: 0;
      border-bottom: 2px solid #1d1d1d6d;
      font-size: 1.5em;
      letter-spacing: 1.32px;
      @include poppinsRegular;
    }

    .floating_label {
      @include descStyleF20L32;
      @include poppinsRegular;
      position: absolute;
      left: 0em;
      transform: translateY(0%);
      color: #1d1d1d6d;
      pointer-events: none;
      transition: 0.2s ease all;
      text-transform: capitalize;
    }
    .input_field:focus {
      border-bottom: 2px solid #1d1d1d;
    }

    .mcm_cta {
      margin-top: 2.5em;
    }
    .input_field_wrapper_flex {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
    .input_select_field_right,
    .input_select_field_left {
      width: 100%;
      max-width: 45%;
      position: relative;
    }
    .contact_dropdown {
      appearance: none;
      -webkit-appearance: none;
      cursor: pointer;
      width: 100%;
      padding: 0 2.5em 0 0.5em;
      color: #000 !important;
      background: url("../../images/general/drop-down-arrow.svg") 94% / 3%
        no-repeat #fff;
    }
    .input_field:focus ~ .floating_label {
      transform: translateY(-100%);
      font-size: 0.875em;
      letter-spacing: 0.28px;
      color: #000000;
    }
    .input_field.active + .floating_label {
      transform: translateY(-100%);
      font-size: 0.875em;
      letter-spacing: 0.28px;
      color: #000000;
    }
  }
}
.contactsec3 {
  padding: 8.125em 14em;
  background-color: $color-primary;
  .map_frame {
    width: 100%;
    min-height: 38.25em;
  }
}

@media (max-width: 1194px) {
  .contactsec2 {
    padding-left: 1.5em;
    padding-right: 3em;
    &::after {
      right: 1.5em;
    }
    .contact_details_flex_left {
      max-width: 45%;
      padding-left: 0.5em;
    }
    .contact_details_flex_right {
      max-width: 55%;
      padding-left: 1em;
    }
  }
  .contactsec3 {
    padding-left: 1.5em;
    padding-right: 1.5em;
  }
}
@media (max-width: 767px) {
  .contactsec2 {
    padding-top: 0;
    padding-right: 1.5em;
    padding-bottom: 0;
    &::after {
      display: none;
    }
    .contact_details_flex {
      flex-direction: column;
    }
    .contact_details_flex_left,
    .contact_details_flex_right {
      padding: 2.4em 1.5em;
      max-width: 100%;
    }
    .contact_details_flex_left {
      //   padding-left: 1em;
      box-sizing: border-box;
      h3 {
        @include mbF16L45;
      }
      .contact_details_wrapper {
        margin-top: 2.5em;
        .contact_details_logo {
          max-width: 3em;
        }
        .add_desc {
          @include mbF16L26;
          max-width: 100%;
          margin-left: 1.5em;
        }
        .mcm_text {
          margin-left: 0em;
          font-size: 1.125em;
        }
        p {
          margin-left: 1.5em;
        }
      }
      .social_icons_wrapper {
        margin-top: 5em;
        max-width: 22.5em;
      }
    }
    .contact_details_flex_left {
      .contact_details_wrapper {
        .contact_details_logo {
          padding-top: 0.5em;
        }
        .contact_details_list_item {
          align-items: flex-start;
          a {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
    .contact_details_flex_right {
      background-color: #fff;
      box-sizing: border-box;
      margin-top: 6em;
      .mcm_cta {
        margin-top: 0.5em;
        font-size: 1.5em
      }
      h3 {
        @include mbF16L45;
      }
      p {
        margin-top: 0;
        @include mbF16L26;
      }
      .input_field_wrapper:nth-child(n + 2) {
        margin-top: 4em;
      }
      .input_field_wrapper_flex {
        flex-direction: column;
      }
      .input_select_field_left,
      .input_select_field_right {
        max-width: 100%;
      }
      .input_select_field_right {
        margin-top: 4em;
      }
      .country_code_field {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        .country_code {
          position: relative;
          width: 100%;
          max-width: 20%;
          text-align: center;
          margin-right: 0.5em;
          .countrycode_select {
            width: 100%;
            text-align: center;
            padding: 0;
          }
        }
        .number_field {
          max-width: 75%;
        }
      }
    }
  }
  .contactsec3 {
    padding-top: 6em;
    padding-bottom: 6.9em;
  }
}
