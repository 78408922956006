@import "../../common.scss";
.projectcategorysec2 {
  padding-top: 5.625em;
  padding-bottom: 9.375em;
  padding-left: 8.875em;
  background-color: $color-primary;
  .title_flex {
    padding-right: 8.75em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .heading_container {
      height: 3em;
      width: 100%;
      overflow: hidden;
      h3 {
        @include headingStyleF50L45;
        @include poppinsSemiBold;
        color: $color-white;
        position: relative;
        width: fit-content;
        transform: translateY(100px);
        transition: all 0.2s ease-in;
        &::after {
          content: "";
          position: absolute;
          width: 2.54em;
          height: 3px;
          background-color: $color-pink;
          top: 50%;
          transform: translateY(-50%);
          right: -3em;
        }
      }
    }
    .nav_arrow_wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      // opacity: 0;
      transition: all 0.2s ease-in;
    }
    .nav_arrow {
      width: 100%;
      max-width: 3.918em;
    }
    .nav_arrow_left {
      padding-right: 2em;
      box-sizing: content-box;
    }
  }
  .projects_slider {
    padding-top: 6.75em;
    // opacity: 0;
    transition: all 0.2s ease-in;
    .swiper-slide {
      position: relative;
      cursor: pointer;
    }
    figure {
      width: 100%;
      max-width: 36.875em;
      position: absolute;
      left: 5em;
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: $color-primary;
        top: 0;
        left: 0;
        opacity: 0.2;
      }
    }
    .project_img {
      width: 100%;
      object-fit: cover;
    }
    .project_name_wrapper {
      position: relative;
      z-index: 2;
      padding-top: 14em;
      padding-bottom: 2em;
      span {
        display: block;
      }
      .project_sequence {
        @include textStyles(1.875em, 1.5em, 3.3px);
        @include poppinsSemiBold;
        color: #ffffff59;
      }
      .project_name {
        @include textStyles(2.9em, 1.6em, normal);
        @include cocogooseRegular;
        color: #f8f8f8;
        text-transform: capitalize;
        width: 100%;
        max-width: 76%;
      }
    }
  }
  .project_category_names_flex {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 5em;
    .cat_name a {
      @include textStyles(1.375em, 2.045em, 2.42px);
      @include poppinsSemiBold;
      text-transform: uppercase;
      color: #ffffff59;
      cursor: pointer;
    }
    .cat_name:nth-child(n + 2) {
      margin-left: 2.273em;
    }
    .cat_name.active a {
      color: $color-pink;
      text-decoration: underline;
      pointer-events: none;
    }
  }
}
@media (max-width: 1194px) {
  .projectcategorysec2 {
    padding-left: $pad-left-right;
  }
}
@media (max-width: 1194px) {
  .projectcategorysec2 {
    .title_flex {
      padding-right: 1.5em;
    }
  }
}
@media (max-width: 767px) {
  .projectcategorysec2 {
    padding-top: 0;
    .title_flex {
      .heading_container {
        display: none;
      }
      h3 {
        @include mbF16L45;
      }
    }
    .projects_slider {
      padding-top: 3em;
      .nav_arrow_wrapper {
        // margin-top: -4em;
        padding-right: 1.5em;
        text-align: center;
      }
      .nav_arrow {
        box-sizing: content-box;
        width: 100%;
        max-width: 3em;
      }
      .nav_arrow_left {
        padding-right: 1em;
      }
      figure {
        max-width: 29.2em;
        left: 50%;
        transform: translateX(-50%);
      }
      .project_name_wrapper {
        padding-top: 18em;
        .project_sequence {
          font-size: 1.6em;
          letter-spacing: 1.76px;
        }
        .project_name {
          font-size: 3em;
          line-height: 1.313em;
          max-width: 90%;
        }
      }
    }
    .project_category_names_flex {
      margin-top: 6em;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      padding-right: 1.5em;
      .cat_name {
        font-size: 1.6em;
        line-height: 1.875em;
      }
      .cat_name:nth-child(n + 2) {
        margin-left: 0;
      }
    }
  }
}
@media (max-width: 340px) {
  .projectcategorysec2 {
    .projects_slider {
      figure {
        max-width: 25.2em;
      }
      .project_name_wrapper {
        padding-top: 15em;
        .project_name {
          font-size: 2.8em;
          max-width: 95%;
        }
      }
    }
  }
}
