/*============================
    General css start Here    
============================*/
$color-primary: #1d1d1d;
$color-pink: #e27663;
$color-white: #ffffff;
$color-greyishWhite: #f8f8f89c;
$pad-left-right: 1.5em;
$pad-left-right30: 3em;
// * {
// outline: 1px solid orangered;
// }
::-webkit-scrollbar {
  width: 8px;
  background: #2b27279c;
}
::-webkit-scrollbar-thumb {
  background: $color-primary;
}
.dummy {
  min-height: 100vh;
  background-color: lightcoral;
}
*,
*:focus,
input:focus,
textarea:focus,
form-control:focus {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
html {
  scroll-behavior: smooth;
}
html,
body,
#root {
  height: 100%;
}
// .dummy {
// height: 100vh;
// }
img {
  height: auto;
  // max-width: 100%;
}
select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
a {
  text-decoration: none;
}
:focus {
  outline: -webkit-focus-ring-color auto 0px;
}
:focus {
  outline: none;
}
::-moz-focus-inner {
  border: 0;
}
button:focus {
  outline: 0px dotted;
  outline: 0px auto -webkit-focus-ring-color;
}
.swiper-wrapper {
  -webkit-transition-timing-function: linear !important;
  -o-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
}
li {
  list-style-type: none;
}
[type="submit"],
button,
html [type="button"] {
  appearance: none;
  -webkit-appearance: none;
}
.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0rem rgb(0 123 255 / 25%);
}
@media (min-width: 2560px) {
  body {
    font-size: 18px;
  }
  .my_container {
    max-width: 2293.3px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1920px) {
  body {
    font-size: 16px;
  }
  .my_container {
    max-width: 1720px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1919px) {
  body {
    font-size: 14px;
  }
  .my_container {
    max-width: 1361.67px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}
@media (max-width: 1536px) {
  body {
    font-size: 11px;
  }
}
@media (max-width: 1440px) {
  body {
    font-size: 10.5px;
  }
  .my_container {
    max-width: 1290px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1366px) {
  body {
    font-size: 10px;
    // font-size: 11px;
  }
  .my_container {
    max-width: 1223.708px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1280px) {
  body {
    font-size: 9.5px;
  }
  .my_container {
    max-width: 1146.67px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1194px) {
  body {
    font-size: 10px;
  }
  .my_container {
    max-width: 1069.625px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 834px) {
  body {
    font-size: 10px;
  }
  .my_container {
    max-width: 747.125px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

/*============================
    General css end Here    
============================*/
/*============================
    MIXINS start Here    
============================*/
@mixin textStyles($fontSize, $lineHeight, $letterSpacing) {
  font-size: $fontSize;
  line-height: $lineHeight;
  letter-spacing: $letterSpacing;
}
@mixin f120L154 {
  font-size: 7.5em;
  line-height: 1.283em;
}
@mixin headingStyleF50L45 {
  font-size: 3.125em;
  line-height: 0.9em;
  letter-spacing: 5.5px;
  text-transform: uppercase;
}
@mixin descStyleF20L32 {
  font-size: 1.25em;
  line-height: 1.6em;
}
@mixin knowLoadMoreStyleF24 {
  font-size: 1.5em;
  line-height: 1.875em;
}
@mixin hollowTextStyleF100 {
  font-size: 6.25em;
  line-height: 1.3em;
}
@mixin mbF16L45 {
  font-size: 1.6em;
  line-height: 2.813em;
  letter-spacing: 1.76px;
}
@mixin mbF16L26 {
  font-size: 1.6em;
  line-height: 1.625em;
}
// fonts mixin start
@mixin poppinsLight {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
}
@mixin poppinsRegular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}
@mixin poppinsMedium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}
@mixin poppinsSemiBold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}
@mixin poppinsBold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}
@mixin cocogooseRegular {
  // font-family: "cocogoose-regular";
  font-family: "Work Sans", sans-serif;
  font-weight: 400;
}
// fonts mixin end

// common styles
.nav_arrow {
  cursor: pointer;
}
.nav_arrow.swiper-button-disabled {
  opacity: 0.5;
  pointer-events: none;
  transition: 0.3s opacity;
}
.hollow_text {
  color: #f8f8f8c2;
  text-transform: capitalize;
  @include textStyles(6.25em, 1.3em, normal);
  @include cocogooseRegular;
}
.mcm_cta {
  @include textStyles(1.5em, 1.875em, normal);
  @include poppinsSemiBold;
  text-transform: capitalize;
  position: relative;
  color: $color-pink;
  cursor: pointer;
  display: flex;
  box-sizing: content-box;
  align-items: center;
  padding-right: 1em;
  transition: all 0.15s ease-in-out;
  &:hover {
    img {
      right: -0.5em;
    }
  }
  img {
    padding-left: 0.7em;
    position: absolute;
    width: 0.5em;
    right: 0;
    box-sizing: content-box;
    transition: all 0.15s ease-in-out;
  }
}
button {
  outline: none;
  border: none;
  background-color: transparent;
}
.error {
  position: absolute;
  bottom: -1.2em;
  color: red;
  font-size: 1.2em;
}

.load_more_cta {
  @include textStyles(1.5em, 1.875em, 0.48px);
  @include poppinsBold;
  color: $color-pink;
  text-transform: capitalize;
  text-decoration: underline;
  cursor: pointer;
}
// end of common styles

/*============================
    MIXINS end Here    
============================*/
/*============================
    common css start Here    
============================*/

/*============================
    common css end Here    
============================*/

/*============================
    Desktop CSS Starts Here   
=============================*/

// @media(min-width:  1280px) {}
// @media(min-width: 2560px) {}
// @media(max-width: 1919px) {}
// @media(max-width: 1536px) {}
// @media(max-width: 1440px) {}
// @media(max-width: 1366px) {}
// @media(max-width: 1280px) {}
/*============================
Desktop CSS Ends Here   
=============================*/

/*================================
Ipad/Mobile CSS Starts Here   
================================*/
// @media(max-width: 1194px) {}
// @media (max-width: 1023px) {}
// @media(max-width: 834px) {}
@media (max-width: 767px) {
  .mcm_cta {
    @include textStyles(1.8em, 2.5em, normal);
    @include poppinsBold;
  }
  .hollow_text {
    @include textStyles(3.2em, 1.313em, normal);
  }
  .load_more_cta {
    @include textStyles(1.8em, normal, 0.48px);
  }
}
// @media(max-width: 375px) {}
// @media(max-width: 320px) {}
/*================================
  Ipad/Mobile CSS Ends Here   
  ================================*/
