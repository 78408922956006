@import "../../common.scss";
.eventssec2 {
  background-color: $color-primary;
  padding: 5.625em 23.063em 7.5em;
  .events_list {
    // opacity: 0;
    transition: all 0.2s ease-in;
  }
  .event_item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 1.875em;
  }
  .event_item:nth-child(n + 2) {
    padding-top: 1.875em;
    border-top: 1px solid #f8f8f8;
  }
  .event_img {
    width: 100%;
    max-width: 30%;
    object-fit: cover;
  }
  .event_details_wrapper {
    width: 100%;
    // max-width: 70%;
    // padding-left: 2.313em;
  }
  .event_title {
    @include textStyles(1.375em, 1.455em, 0.55px);
    @include poppinsSemiBold;
    color: #f8f8f8;
  }
  .events_timing_details_flex {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    margin-top: 1em;
    margin-bottom: 1.3em;
    .events_timing_item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      max-width: 100%;
      margin-bottom: 0.5em;
    }
    .event_timing_logo {
      max-width: 1.25em;
      width: 100%;
    }
    span {
      @include textStyles(1.125em, 1.667em, 0.45px);
      @include poppinsRegular;
      color: #f8f8f8;
      opacity: 0.5;
      padding-left: 0.5em;
    }
  }
  .event_desc {
    @include descStyleF20L32;
    @include poppinsRegular;
    color: #f8f8f8;
  }
  .mcm_cta {
    margin-top: 1em;
  }
  .load_more_wrapper {
    text-align: center;
    margin-top: 6.25em;
  }
}
@media (max-width: 1194px) {
  .eventssec2 {
    padding-left: 1.5em;
    padding-right: 1.5em;
  }
}
@media (max-width: 767px) {
  .eventssec2 {
    padding-top: 0;
    padding-bottom: 6em;
    .event_item {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .event_img {
      max-width: 100%;
    }
    .event_details_wrapper {
      max-width: 100%;
      padding-left: 0;
    }
    .event_title {
      font-size: 1.6em;
      margin-top: 1.5em;
    }
    .events_timing_details_flex {
      flex-direction: column;
      align-items: flex-start;
      .events_timing_item {
        max-width: 100%;
      }
      span {
        font-size: 1.4em;
        letter-spacing: 0.35px;
      }
    }
    .event_desc {
      @include mbF16L26;
    }
    .mcm_cta {
      margin-top: 0.4em;
    }
    .load_more_wrapper {
      margin-top: 5em;
    }
  }
}
