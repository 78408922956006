@import "../../common.scss";
.faqsec2 {
  padding: 5.625em 8.75em 7.5em;
  background-color: $color-primary;
  .faq_item {
    padding: 1.875em;
    padding-right: 12.5em;
    box-shadow: 9px 9px 6px #00000042;
    position: relative;
    cursor: pointer;
  }
  .faq_item.active {
    box-shadow: none;
  }
  .faq_item:nth-child(n + 2) {
    margin-top: 2.5em;
  }
  .faq_title {
    @include textStyles(1.25em, 1.75em, 0.2px);
    @include poppinsMedium;
    color: #f8f8f8;
  }
  .faq_desc {
    @include textStyles(1.125em, 1.556em, 0.18px);
    @include poppinsRegular;
    color: #f8f8f8;
    // height: 0;
    overflow: hidden;
    transition: transform 0.3s ease-out; // note that we're transitioning transform, not height!
    height: 0;
    transform: scaleY(0); // implicit, but good to specify explicitly
    transform-origin: top;
  }
  .faq_desc.active {
    margin-top: 1em;
    height: auto;
    transform: scaleY(1);
  }
  .faq_open_close_logo {
    position: absolute;
    width: 100%;
    max-width: 1.688em;
    top: 1.875em;
    right: 1.875em;
  }
}
// @keyframes faqAnim {
//     0% {
//       height: 0;
//       overflow: hidden;
//     }
//   100% {
//     height: auto;
//     overflow: visible;
//   }
// }
@media (max-width: 834px) {
  .faqsec2 {
    padding-left: 1.5em;
    padding-right: 1.5em;
  }
}
@media (max-width: 767px) {
  .faqsec2 {
    padding-top: 0;
    padding-bottom: 6em;
    .faq_item {
      padding-right: 3.5em;
    }
    .faq_title,
    .faq_desc {
      @include mbF16L26;
    }
  }
}
